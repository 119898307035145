import React, { useEffect, useState } from 'react';
import { css } from '@emotion/css';
import ApplicationContainer from '../../../../state/containers/ApplicationContainer';
import { shade_2_lighter, cool_grey } from '../../../../constants/colors';
import LanguageMultiSelector from './LanguageMultiSelector';
import LanguageCard from './LanguageCard';
import HorizontalSeparator from '../../../../components/structure/HorizontalSeparator';
import _ from 'lodash';
import Button from '../../../../components/general/Button';
import ContentLanguageMultiSelector from '../../../../components/forms/ContentLanguageMultiSelector';
import ToggleIndicator from '../../../../components/general/ToggleIndicator';
import styled from "@emotion/styled";
import LanguageContainer from '../../../../hooks/LanguageContainer';
import LanguageModel from '../../../../models/features/api/LanguageModel';
import InteractionModelUiStateContainer from "../../../../hooks/InteractionModelUiStateContainer";
import LanguageWarning from './LanguageWarning';
import CustomScrollbars from '../../../../components/structure/CustomScrollbars';
import ConfirmationDialog from '../../../../components/structure/ConfirmationDialog';

interface LanguageSettingsProps {
    appContainer: ApplicationContainer
    applicationId: string
    clearSelection: boolean
}

const LanguageSettings: React.FC<LanguageSettingsProps> = (props) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [showSyncConfirmation, setShowSyncConfirmation] = useState(false);

    const languagesContainer = LanguageContainer.useContainer();
    const InteractionModelStateContainer = InteractionModelUiStateContainer.useContainer();

    useEffect(() => {
        const app = props.appContainer.state.currentApp;
        const getLanguages = async () => {
            await languagesContainer.loadLanguages();
        }
        if (languagesContainer.languages.length < 1) {
            getLanguages();
        }
        const defaultLanguage = languagesContainer.languages.find(l => l.id == app.defaultLanguageId);
        const languages = (defaultLanguage == null || props.clearSelection) ? [] : [defaultLanguage];
        languagesContainer.updateSelectedLanguages(languages);
    }, [props.appContainer.state.currentApp.defaultLanguageId]);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const getAppLanguages = () => {
        const appLanguages = props.appContainer.state.currentApp.languages;
        return appLanguages;
    };

    const handleSelectedLanguages = (languageIds: string[]) => {
        const appLanguages = getAppLanguages();
        const selectedLanguages = appLanguages.filter(al => languageIds.some(l => l == al.id));
        languagesContainer.updateSelectedLanguages(selectedLanguages);
    };

    const handleLanguageUpdate = (language: LanguageModel, isSelected: boolean) => {
        const appLanguages = getAppLanguages();
        if (isSelected) {
            appLanguages.push(language);
        }
        else {
            _.remove(appLanguages, l => l.id == language.id);
        }

        props.appContainer.updateApplicationLanguages(props.applicationId, appLanguages);
    };

    const handleDefaultLanguageChange = (languages: LanguageModel[]) => {
        if (languages && languages[0]) {
            props.appContainer.updateApplicationDefaultLanguage(props.applicationId, languages[0].id);
        }
        else {
            props.appContainer.updateApplicationDefaultLanguage(props.applicationId, '');
        }
    }
    function toggleShowSyncModal() {
        setShowSyncConfirmation(!showSyncConfirmation)
    }

    function handleEntitiesAndIntentsSync(languageIds: string[], sourceLanguageId: string) {
        props.appContainer.syncNLPAndInteractionModel(props.applicationId, languageIds, sourceLanguageId);
    }


    const app = props.appContainer.state.currentApp;
    const appLanguages = getAppLanguages();
    const allLangs = _.sortBy(languagesContainer.languages,
        lang => lang.languageGroupCode != "en",
        lang => lang.languageGroupCode,
        lang => lang.supportedPlatforms == "*",
        lang => lang.supportedPlatforms.length)

    var defaultLanguage = appLanguages.find(appLanguage => appLanguage.id === app.defaultLanguageId);
    const languagesToBeSynced = languagesContainer.currentSelectedLanguages.filter(l => l.id !== app.defaultLanguageId);
    const showSyncButton = languagesToBeSynced.length > 0;
    return (
        <LanguageContainerDiv>
            <div className="top-banner">
                <div className="language-dropdown">
                    <LanguageMultiSelector
                        languages={appLanguages}
                        selectedLanguageIds={languagesContainer.currentSelectedLanguages.map(l => l.id)}
                        onChange={handleSelectedLanguages}
                        warningRender={
                            <LanguageWarning
                                isDirty={InteractionModelStateContainer.isFormDirty}
                                key={"language-warning"} />
                        }
                    />
                </div>
                <Button themes={['white', 'end-tight']} additionalClasses="language-button" type='button' text="Language Settings" onClick={toggleExpand.bind(this)} rightComponent={() => <ToggleIndicator direction={isExpanded ? 'up' : 'down'} />} />
            </div>
            {isExpanded ?
                <CustomScrollbars>
                    {showSyncButton &&
                        <Button
                            themes={['primary-small', 'end-tight']}
                            type="button"
                            additionalClasses="language-button"
                            text={`Sync ${languagesToBeSynced.map(l => l.shortCode).join(",")} with the default language: ${defaultLanguage.shortCode}`}
                            onClick={toggleShowSyncModal}
                            disabled={props.appContainer.state.isSyncingNLPAndInteractionModel}
                            loading={props.appContainer.state.isSyncingNLPAndInteractionModel}
                        />
                    }
                    <div className="language-settings">
                        <HorizontalSeparator />
                        <div className="language-settings-header">
                            <p className="language-label">Select the languages and regions you’d like to support.</p>
                            <ContentLanguageMultiSelector isCollapsed label="Default:" languages={appLanguages} selectedLanguageIds={app.defaultLanguageId ? [app.defaultLanguageId] : []} onChange={handleDefaultLanguageChange.bind(this)} />
                        </div>
                        <div className="language-container">
                            {allLangs.map(l => (
                                <LanguageCard key={l.id} language={l} onChange={handleLanguageUpdate.bind(this)} isSelected={appLanguages.some(al => al.id == l.id)} />
                            ))}
                        </div>
                    </div>
                </CustomScrollbars>
                : null}
            {showSyncConfirmation &&
                <ConfirmationDialog
                    title={"Sync NLP entities and intents"}
                    deleteText="Yes, Close"
                    warning={true}
                    onClose={toggleShowSyncModal}
                    onConfirm={() => {
                        handleEntitiesAndIntentsSync(languagesToBeSynced.map(l => l.id), defaultLanguage.id)
                        toggleShowSyncModal();
                    }}>
                    <div style={{ padding: "30px" }}>
                        <p>
                            This will copy and replace all entities and intents from {defaultLanguage.shortCode} to {languagesToBeSynced.map(l => l.shortCode).join(",")}.
                        </p>
                        <p>
                            Are you sure you would like to proceed?
                        </p>
                    </div>
                </ConfirmationDialog>
            }
        </LanguageContainerDiv>
    )
};

const LanguageContainerDiv = styled.div`
    background: ${shade_2_lighter};
    padding: 12px 50px;  
    border-bottom: solid 1px #c7cfd2;

    .sub-item {
        img {
            border-radius: 50%;
            border: 1px solid ${cool_grey};            
        }
    }

    .top-banner {
        display: flex;
        .language-dropdown {
            flex: 1;
            margin-right: 32px;
        }
        .language-button {
            margin-top: 0;
            margin-bottom: 0;
            font-family: Muli;
            font-size: 14px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.31px;
            color: #2c3d52;
            height: 45px;

            .toggle-indicator {
                margin-left: 20px;
                margin-right: -20px;
            }
        }
    }
    .language-settings {
        margin-top: 20px;
        .language-label {
            font-family: Muli;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #2c3d52;
            margin: 16px;
        }
        .language-container {
            display: flex;
            flex-wrap: wrap;
        }
        .language-settings-header {
            display: flex;
            align-items: center;

            .language-grouped-selector {
                margin-right: 0;
                margin-left: auto;
                flex: 0;
            }
            
        }
    }
`;

export default LanguageSettings;