import React, { useState, useEffect, FocusEvent, ReactElement } from 'react';
import { css } from '@emotion/css';
import { Field, FieldValidator } from 'formik';
import { dark_grey_blue, faded_red, cool_grey, pale_grey, color_shades_dark, color_shades_darkest, color_variants_ocean_light_opaque, color_shades_darker } from '../../constants/colors';
import FieldError from './FieldError';
import Button from '../general/Button';
import IconTooltip from '../general/IconTooltip';
import VerticalSeparator from '../structure/VerticalSeparator';
import TooltipWrapper from '../general/TooltipWrapper';
import { breakpoint_small } from '../../constants/breakpoints';

const deleteIcon = require('../../content/images/trash.svg');

type DeletePosition = "top" | "right";

type IconPosition = "left" | "right";

// component used in building forms with consistent components for look and function
interface TextFieldProps {
    name?: string
    type?: string
    placeholder?: string
    value?: string | number
    disabled?: boolean
    onChange?: (e: React.ChangeEvent<any>) => void
    onBlur?: (e: FocusEvent<any>) => void
    onFocus?: (e: FocusEvent<any>) => void
    onDelete?: () => void
    onKeyPress?: (e) => void
    label?: string | ReactElement
    rightLabel?: string
    rightLabelStyle?: string
    id?: string
    canDelete?: boolean
    canCopy?: boolean
    defaultValue?: string | number
    required?: boolean
    tooltip?: string
    tooltipStyle?: string
    className?: string
    icon?: string
    labelIcon?: string
    iconPosition?: IconPosition
    deletePosition?: DeletePosition
    toolTipPosition?: string
    useForm?: boolean
    autoFocus?: boolean
    suggestions?: string[]
    onSuggestionClick?: (suggestion: string) => void
    readOnly?: boolean
    titleText?: string
    fieldContainerStyle?: string
    validate?: FieldValidator; //(value: any) => string | void | Promise<string | void>; 
    fieldStyle?: string
    labelStyle?: string
    onKeyDown?: (e: React.KeyboardEvent<any>) => void
}

const TextField: React.FC<TextFieldProps> = (props) => {
    const [focused, setFocused] = useState<boolean>(false);
    const [suggestionArrowHovered, setSuggestionArrowHovered] = useState<boolean>(false);
    const [focusedSuggestion, setFocusedSuggestion] = useState<number>();
    const [availableSuggestions, setAvailableSuggestions] = useState<string[]>([]);

    const handleDocumentClick = (e: any) => {
        if (e.target.closest(`.suggestion`) || e.target.closest(`.${textFieldStyles}`) || e.target.closest(`.${suggestionsStyle}`)) return;
    }

    const getAvailableSuggestions = () => {
        const availableSuggestions: string[] = [];
        if (props.suggestions && focused) {
            const lowerValue = `${props.value}`.toLowerCase();
            props.suggestions.forEach(s => {
                if (!props.value || props.value == "")
                    availableSuggestions.push(s);

                else if (s.toLowerCase().includes(lowerValue))
                    availableSuggestions.push(s)
            });
        }
        return availableSuggestions;
    }

    useEffect(() => {
        document.addEventListener("click", handleDocumentClick, true);
        return () => {
            document.removeEventListener("click", handleDocumentClick, true);
        }
    }, [])

    useEffect(() => {
        setSuggestionArrowHovered(false);
        const newAvailableSuggestions = getAvailableSuggestions();
        setAvailableSuggestions(newAvailableSuggestions);
    }, [props.value, focused])

    const onFocus = (e: FocusEvent<any>) => {
        if (props.suggestions)
            setFocused(true);

        if (props.onFocus)
            props.onFocus(e);
    }

    const onBlur = (e: FocusEvent<any>) => {
        if (props.suggestions)
            setFocused(false);

        if (props.onBlur)
            props.onBlur(e);
    }

    const onKeyDown = (e: React.KeyboardEvent<any>) => {
        if (availableSuggestions.length > 0) {
            if (e.key == "Enter") {
                if (focusedSuggestion != null)
                    props.onSuggestionClick(availableSuggestions[focusedSuggestion]);
                e.preventDefault();
            }
            if (e.key == "ArrowDown") {
                let newFocusedSuggestion = (focusedSuggestion ?? -1) + 1;
                if (newFocusedSuggestion == availableSuggestions.length) {
                    newFocusedSuggestion = availableSuggestions.length - 1
                    setFocusedSuggestion(newFocusedSuggestion)
                }
            }
            if (e.key == "ArrowUp" && focusedSuggestion != null) {
                let newFocusedSuggestion = focusedSuggestion;
                if (newFocusedSuggestion == 0)
                    newFocusedSuggestion = null;
                else {
                    newFocusedSuggestion = newFocusedSuggestion - 1;
                }
                setFocusedSuggestion(newFocusedSuggestion);
            }
        }
    }

    const onSuggestionClick = (event, suggestion: string) => {
        event.stopPropagation();
        props.onSuggestionClick(suggestion);
    }

    const onSuggestionMouseLeave = () => {
        setSuggestionArrowHovered(false);
    }

    const onSuggestionMouseEnter = (idx: number) => {
        if (idx == 0) {
            setSuggestionArrowHovered(true);
        }
    }

    const showDeleteButton: boolean = props.canDelete && !props.canCopy && (props.deletePosition == undefined || props.deletePosition == "top");

    return (
        <div className={`${containerStyle} ${props.className}`}>
            <div className={labelsStyle}>
                <div className={labelContainer}>
                    {props.labelIcon && <img src={props.labelIcon} />}
                    {props.label !== undefined ? <label htmlFor={props.name} className={`${props.labelStyle && props.labelStyle} labelStyle`}>{props.label}</label> : null}
                    {showDeleteButton ? <Button additionalClasses={deleteIconStyle} type="button" onClick={props.onDelete} themes={["icon"]} icon={deleteIcon} /> : null}
                    {props.required && <TooltipWrapper text="Required field"><span className={requiredLabelStyle}>*</span></TooltipWrapper>}
                    {props.tooltip && <IconTooltip text={props.tooltip} place={props.toolTipPosition ? props.toolTipPosition : "top"} className={props.tooltipStyle} />}
                </div>
                <div>
                    {props.rightLabel !== undefined ? <label htmlFor={props.rightLabel} className={`${props.rightLabelStyle ? props.rightLabelStyle : labelStyle}`}>{props.rightLabel}</label> : null}
                </div>
            </div>
            <div className={fieldWrapper}>
                <div className={`${fieldContainer} ${props.fieldContainerStyle}  ${props.disabled ? 'disabled' : ''} field-container`}>
                    {props.iconPosition !== "right" && props.icon !== undefined ?
                        <img src={props.icon} /> : null}
                    {props.iconPosition !== "right" && props.icon !== undefined ?
                        <div className={separatorStyle}>
                            <VerticalSeparator />
                        </div> : null}
                    {props.useForm === undefined || props.useForm == true ?
                        <Field id={props.id}
                            className={props.fieldStyle ? props.fieldStyle : textFieldStyles}
                            type={props.type}
                            name={props.name}
                            autoFocus={props.autoFocus}
                            placeholder={props.placeholder}
                            value={props.value}
                            defaultValue={props.defaultValue}
                            onChange={props.onChange}
                            onBlur={onBlur}
                            onFocus={onFocus}
                            disabled={props.disabled}
                            onKeyDown={props.onKeyDown ? props.onKeyDown : onKeyDown}
                            onKeyPress={props.onKeyPress}
                            validate={props.validate} />
                        :
                        <input id={props.id}
                            className={textFieldStyles}
                            type={props.type}
                            name={props.name}
                            autoFocus={props.autoFocus}
                            defaultValue={props.defaultValue?.toString()}
                            placeholder={props.placeholder}
                            value={props.value}
                            onChange={props.onChange}
                            onBlur={onBlur}
                            readOnly={props.readOnly ?? false}
                            onFocus={onFocus}
                            onKeyPress={props.onKeyPress}
                            onKeyDown={onKeyDown}
                            disabled={props.disabled} />}
                    {props.iconPosition === "right" &&
                        <>
                            <div className={separatorStyle}>
                                <VerticalSeparator />
                            </div>
                            <img src={props.icon} />
                        </>}
                </div>
                {props.canDelete && props.deletePosition == "right" ? <Button additionalClasses={deleteLargeIconStyle} type="button" onClick={props.onDelete} themes={["icon"]} icon={deleteIcon} /> : null}
            </div>
            {(availableSuggestions.length > 0) &&
                <div className={`${suggestionsStyle} text-field-suggestions`}>
                    <div className={`arrow ${(suggestionArrowHovered || focusedSuggestion == 0) ? 'hovered' : ''}`} />
                    {availableSuggestions.map((suggestion, idx) =>
                        <div
                            onMouseDown={(e) => onSuggestionClick(e, suggestion)}
                            key={idx}
                            onMouseEnter={() => onSuggestionMouseEnter(idx)}
                            onMouseLeave={onSuggestionMouseLeave}
                            className={`suggestion ${idx == 0 ? 'first' : ''} ${idx == availableSuggestions.length - 1 ? 'last' : ''} ${focusedSuggestion == idx ? 'focused-suggestion' : ''}`}
                        >
                            {suggestion}
                        </div>
                    )}
                </div>
            }
            {props.useForm === undefined || props.useForm == true ?
                <FieldError name={props.name} />
                : null}
        </div>
    );
}

const suggestionsStyle = css`
    position: absolute;
    margin-top: 12px;
    background: white;
    width: 400px;
    border-radius: 8px;
    .suggestion {
        font-size: 14px;
        line-height: 20px;
        color: ${color_shades_darkest};
        border-bottom: 1px solid ${color_shades_dark};
        border-left: 1px solid ${color_shades_dark};
        border-right: 1px solid ${color_shades_dark};
        padding: 14px 16px;
        background: white;
        cursor: pointer;
        &.first {
            border-top: 1px solid ${color_shades_dark};
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
        }
        &.last {
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
        }
        &.focused-suggestion {
            background: ${color_variants_ocean_light_opaque};
        }
        &:hover {
            background: ${color_variants_ocean_light_opaque};
        }
    }
    
    .arrow {            
        border-left: solid 1px ${color_shades_dark};
        background: white;
        border-top: solid 1px ${color_shades_dark};
        transform: rotate(45deg);
        position: absolute;         
        width: 9px;
        height: 9px;
        top: -4px;
        left: 20px;
        &.hovered {
            background: ${color_variants_ocean_light_opaque};
        }
    }
`;

const textFieldStyles = css`
    color: ${dark_grey_blue};
    font-family: Muli;
    text-align: left;
    width: 100%;
    border-radius: 5px;
    border: none; 
    padding: 12px 16px;
    font-size: 14px;
    background: transparent;
    ::placeholder {
        font-weight: normal;
        font-style: italic;
        letter-spacing: normal;
        color: ${color_shades_darker};
    }
`;

const containerStyle = css`
margin-bottom: 24px;
flex: 1;
&.tight {
    margin-bottom: 16px;
    label {
        margin: 8px 16px 0 16px;
    }
}
`
const labelStyle = css`
font-family: Muli;
font-size: 14px;
text-align: left;
margin: 0 16px;
`;

const deleteIconStyle = css`
    height: 14px;
    margin: 0;
    img {
        height: 14px;
    }
`

const deleteLargeIconStyle = css`
    height: 32px;
    margin-left: 24px;
    margin-right: 0;
    img {
        height: 32px;
        
        &.left-img {
            margin-right: 0;
        }
    }
`

const labelContainer = css`
    display: flex;
    align-items: center;
    ${breakpoint_small} {
        margin-left: 12px;
        font-family: Muli;
        font-size: 14px;
        font-weight: 600;
    }
`;

const labelsStyle = css`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

const requiredLabelStyle = css`
    margin-right: 0;
    margin-left: auto;
    margin-top: -14px;
    margin-bottom: -20px;
    font-family: Muli;
    font-size: 24px;
    color: ${faded_red};
`

const fieldContainer = css`
display: flex;
justify-content: space-between;
align-items: center;
height: 60px;
width: 100%;
border-radius: 8px;
border: solid 1px ${color_shades_dark}; 
background: white;
margin-top: 8px;
img {
    margin: 16px;
}
&.disabled {
    background: ${pale_grey};
    input {
        background: ${pale_grey};
    }
}
`
const separatorStyle = css`
    height: 30px;
`
const fieldWrapper = css`
display: flex;
flex-direction: row;
align-items: center;

`

export default TextField;