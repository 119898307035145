import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useForm, Controller, Resolver } from "react-hook-form";
import TextAreaField from "../../../components/forms/TextAreaField";
import Button from "../../../components/general/Button";
import { color_colors_ocean, color_defaults_white, dark_red } from "../../../constants/colors";
import CustomScrollbars from "../../../components/structure/CustomScrollbars";

interface GenAIProps {
    isLoading: boolean;
    applicationId: string;
    handleSave: (systemPrompt: string) => void;
    currentPrompt: string;
};

interface FormInputs {
    systemPrompt: string;
}

const GenAI: React.FC<GenAIProps> = (props) => {

    const resolver: Resolver<FormInputs> = async (values) => {
        const errors: any = {};
        if (!values.systemPrompt?.trim()) {
            errors.systemPrompt = {
                type: 'required',
                message: 'System prompt is required.',
            };
        }
        return {
            values: Object.keys(errors).length ? {} : values,
            errors,
        };
    };

    const { control, handleSubmit, formState: { errors } } = useForm<FormInputs>({
        resolver,
        mode: "onChange",
        defaultValues: {
            systemPrompt: props.currentPrompt ?? ""
        }
    });

    const onSubmit = (data: FormInputs) => {
        props.handleSave(data.systemPrompt);
    };

    return (
        <>
            <Container>
                <ButtonContainer>
                    <SaveButton
                        type="submit"
                        disabled={props.isLoading}
                        themes={["primary"]}
                        text="Save System Prompt"
                        loading={props.isLoading}
                        onClick={handleSubmit(onSubmit)}
                    />
                </ButtonContainer>
                <CustomScrollbars autoHide>
                    <Controller
                        name="systemPrompt"
                        control={control}
                        render={({ field }) => (
                            <TextAreaField
                                id="systemPrompt"
                                {...field}
                                placeholder="Enter your system prompt here"
                                label="System Prompt"
                                tooltip="A system level prompt given to the generative AI model that describes how it should respond"
                                className="system-prompt-field"
                            />
                        )}
                    />
                    {errors.systemPrompt && (
                        <ErrorMessageWrapper>
                            <p>{errors.systemPrompt.message}</p>
                        </ErrorMessageWrapper>
                    )}
                </CustomScrollbars>
            </Container>
        </>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0px;
    padding: 0px 24px;
    margin: 20px 40px;
    height: calc(100vh - 250px);
    background: ${color_defaults_white};
    border-radius: 12px;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.06);
    transition: all 0.2s ease-in-out;

    .system-prompt-field {
        width: 100%;
        label {
            margin-left: 6px;
        }
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
`;

const SaveButton = styled(Button)`
    background-color: ${color_colors_ocean};
    height: 40px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 8px;
`;


const ErrorMessageWrapper = styled.div`
    color: ${dark_red};
    font-size: 14px;
    margin-top: 4px;
    margin-left: 6px;
`;

export default GenAI;