import React from "react";
import NotificationsContainer from "../../shared/hooks/NotificationsContainer";
import LanguageContainer from "../../shared/hooks/LanguageContainer";
import SimulatorContainer from "../../shared/hooks/SimulatorContainer";
import TermsContainer from "../../shared/hooks/TermsContainer";
import DialogflowDeploymentContainer from "../../shared/hooks/DialogflowDeploymentContainer";
import AmazonDeploymentContainer from "../../shared/hooks/AmazonDeploymentContainer";
import MicrosoftDeploymentContainer from "../../shared/hooks/MicrosoftDeploymentContainer";
import CustomAssistantContainer from "../../shared/hooks/CustomAssistantContainer";
import ApplicationNlpEntityContainer from "../../shared/hooks/ApplicationNlpEntityContainer";
import InteractionModelContainer from "../../shared/hooks/InteractionModelContainer";
import InteractionModelUiStateContainer from "../../shared/hooks/InteractionModelUiStateContainer";
import NlpModelTrainingContainer from "../../shared/hooks/NlpModelTrainingContainer";
import DeploymentsContainer from "../../shared/hooks/DeploymentsContainer";
import CustomAssistantConfigurationsContainer from "../../shared/scenes/applicationSettings/hooks/useCustomAssistantConfigurationSettings";
import SessionAnalyticsContainer from "../../shared/hooks/SessionAnalyticsContainer";
import SessionAnalyticsContainerOld from "../../shared/hooks/SessionAnalyticsContainerOld";
import ApplicationNlpMenuContainer from "../../shared/hooks/ApplicationNlpMenuContainer";
import TelephonyDeploymentContainer from "../../shared/hooks/TelephonyDeploymentContainer";
import NlpContainer from "../../shared/hooks/NlpContainer";
import AnalyticsEventStreamContainer from '../../shared/hooks/AnalyticsEventStreamContainer';
import OrderingAnalyticsContainer from "../../shared/hooks/OrderingAnalyticsContainer";
import OrgSetupContainer from "../../shared/hooks/OrgSetupContainer";
import SparkContainer from "../../shared/hooks/SparkContainer";
import OrgAnalyticsContainer from "../../shared/hooks/OrgAnalyticsContainer";
import SparkDeploymentContainer from "../../shared/hooks/SparkDeploymentContainer";
import AnalyticsOrderStateContainer from "../../shared/hooks/AnalyticsOrderStateContainer";
import MenuContainer from "../../shared/hooks/MenuContainer";
import MenuSyncContainer from "../../shared/hooks/MenuSyncContainer";
import OutboundCallContainer from "../../shared/hooks/OutboundCallContainer";
import ContentContainer from "../../shared/hooks/ContentContainer";
import ApplicationNlpGenAIContainer from "../../shared/hooks/ApplicationNlpGenAIContainer";

const ContainerProvider: React.FC = (props) => {
    return (
        <OrderingAnalyticsContainer.Provider >
            <NotificationsContainer.Provider>
                <LanguageContainer.Provider>
                    <TermsContainer.Provider>
                        <InteractionModelContainer.Provider>
                            <InteractionModelUiStateContainer.Provider>
                                <SimulatorContainer.Provider>
                                    <TelephonyDeploymentContainer.Provider>
                                        <CustomAssistantContainer.Provider>
                                            <DialogflowDeploymentContainer.Provider>
                                                <AmazonDeploymentContainer.Provider>
                                                    <MicrosoftDeploymentContainer.Provider>
                                                        <ApplicationNlpEntityContainer.Provider>
                                                            <ApplicationNlpMenuContainer.Provider>
                                                                <ApplicationNlpGenAIContainer.Provider>
                                                                    <MenuSyncContainer.Provider>
                                                                        <MenuContainer.Provider>
                                                                            <OutboundCallContainer.Provider>
                                                                                <NlpModelTrainingContainer.Provider>
                                                                                    <DeploymentsContainer.Provider>
                                                                                        <CustomAssistantConfigurationsContainer.Provider>
                                                                                            <SessionAnalyticsContainer.Provider>
                                                                                                <SessionAnalyticsContainerOld.Provider>
                                                                                                    <AnalyticsEventStreamContainer.Provider>
                                                                                                        <NlpContainer.Provider>
                                                                                                            <OrgSetupContainer.Provider>
                                                                                                                <ContentContainer.Provider>
                                                                                                                    <SparkContainer.Provider>
                                                                                                                        <SparkDeploymentContainer.Provider>
                                                                                                                            <OrgAnalyticsContainer.Provider>
                                                                                                                                <AnalyticsOrderStateContainer.Provider>
                                                                                                                                    {props.children}
                                                                                                                                </AnalyticsOrderStateContainer.Provider>
                                                                                                                            </OrgAnalyticsContainer.Provider>
                                                                                                                        </SparkDeploymentContainer.Provider>
                                                                                                                    </SparkContainer.Provider>
                                                                                                                </ContentContainer.Provider>
                                                                                                            </OrgSetupContainer.Provider>
                                                                                                        </NlpContainer.Provider>
                                                                                                    </AnalyticsEventStreamContainer.Provider>
                                                                                                </SessionAnalyticsContainerOld.Provider>
                                                                                            </SessionAnalyticsContainer.Provider>
                                                                                        </CustomAssistantConfigurationsContainer.Provider>
                                                                                    </DeploymentsContainer.Provider>
                                                                                </NlpModelTrainingContainer.Provider>
                                                                            </OutboundCallContainer.Provider>
                                                                        </MenuContainer.Provider>
                                                                    </MenuSyncContainer.Provider>
                                                                </ApplicationNlpGenAIContainer.Provider>
                                                            </ApplicationNlpMenuContainer.Provider>
                                                        </ApplicationNlpEntityContainer.Provider>
                                                    </MicrosoftDeploymentContainer.Provider>
                                                </AmazonDeploymentContainer.Provider>
                                            </DialogflowDeploymentContainer.Provider>
                                        </CustomAssistantContainer.Provider>
                                    </TelephonyDeploymentContainer.Provider>
                                </SimulatorContainer.Provider>
                            </InteractionModelUiStateContainer.Provider>
                        </InteractionModelContainer.Provider>
                    </TermsContainer.Provider>
                </LanguageContainer.Provider>
            </NotificationsContainer.Provider>
        </OrderingAnalyticsContainer.Provider >
    );
}

export default ContainerProvider;

