import React, { useEffect } from "react";
import { Route, Redirect, RouteComponentProps } from "react-router-dom";
import AuthContainer from "../../../../shared/state/containers/AuthContainer";
import { Subscribe } from "unstated";
import { css } from '@emotion/css';
import OrganizationContainer from "../../../../shared/state/containers/OrganizationContainer";
import Splash from "../../../../shared/scenes/splash";
import CreateExitMessage from "../../../../shared/scenes/createExitMessage";
import EditExitMessage from "../../../../shared/scenes/editExitMessage";
import CreateHelpMessage from "../../../../shared/scenes/createHelpMessage";
import EditHelpMessage from "../../../../shared/scenes/editHelpMessage";
import OrganizationDashboard from "../../../../shared/scenes/organizationDashboard";
import ApplicationDashboard from "../../../../shared/scenes/applicationDashboard";
import CreateWelcomeMessage from "../../../../shared/scenes/createWelcomeMessage";
import EditWelcomeMessage from "../../../../shared/scenes/editWelcomeMessage";
import CreateQuestionAnswer from "../../../../shared/scenes/createQuestionAnswer";
import EditQuestionAnswer from "../../../../shared/scenes/editQuestionAnswer";
import CreateLatestMessage from "../../../../shared/scenes/createLatestMessage";
import EditLatestMessage from "../../../../shared/scenes/editLatestMessage";
import Logout from "../../../../shared/scenes/logout";
import CreateApplication from "../../../../shared/scenes/createApplication";
import ApplicationContainer from "../../../../shared/state/containers/ApplicationContainer";
import OrganizationMemberInvite from "../../../../shared/scenes/organizationMemberInvite";
import AddApplicationMember from "../../../../shared/scenes/addApplicationMember";
import EditFallbackMessage from "../../../../shared/scenes/editFallbackMessage";
import CreateFallbackMessage from "../../../../shared/scenes/createFallbackMessage";
import CreateNumberRange from "../../../../shared/scenes/createNumberRange";
import EditNumberRange from "../../../../shared/scenes/editNumberRange";
import CreateSimpleChoice from "../../../../shared/scenes/createSimpleChoice";
import EditSimpleChoices from "../../../../shared/scenes/editSimpleChoices";
import UserContainer from "../../../../shared/state/containers/UserContainer";
import CreateCustomRequest from "../../../../shared/scenes/createCustomRequest";
import EditCustomRequest from "../../../../shared/scenes/editCustomRequest";
import FeatureContainer from "../../../../shared/state/containers/FeatureContainer";
import UrgentNotificationSplash from "../../../../shared/components/general/UrgentNotificationSplash";
import CreateTemplateConfiguration from "../../../../shared/scenes/createTemplateConfiguration";
import EditTemplateConfiguration from "../../../../shared/scenes/editTemplateConfiguration";
import ApplicationAppliedTemplateManager from "../../../../shared/scenes/applicationAppliedTemplateManager";
import NewTermsModal from "../../../../shared/components/general/NewTermsModal";
import User from "../../../../shared/models/user/User";
import Loader from "../../../../shared/components/general/Loader";

const Dashboard: React.FC<RouteComponentProps<{}>> = (props) => (

    <Subscribe to={[AuthContainer,
        UserContainer,
        OrganizationContainer,
        ApplicationContainer,
        FeatureContainer,
    ]}>
        {(auth: AuthContainer,
            userContainer: UserContainer,
            orgs: OrganizationContainer,
            appContainer: ApplicationContainer,
            featureContainer: FeatureContainer,
        ) => {
            // if we are logged in, go to root
            if (auth.state.isAuthenticated !== true || auth.state.expirationDate === undefined) {
                return <Redirect to="/login" />
            }
            // if we have no current org, go to orgs page
            if (orgs.state.currentOrganization === null || auth.state.expirationDate < new Date() || featureContainer.state.systemFeatures.length == 0) {
                return <Splash
                    authContainer={auth}
                    orgContainer={orgs}
                    appContainer={appContainer}
                    featureContainer={featureContainer} />
            }
            return <InnerDashboard
                authContainer={auth}
                userContainer={userContainer} />
        }
        }
    </Subscribe>
);

interface InnerDashboardProps {
    userContainer: UserContainer
    authContainer: AuthContainer
}
const InnerDashboard: React.FC<InnerDashboardProps> = ({
    userContainer,
    authContainer
}) => {
    useEffect(() => {
        if (authContainer.state.isAuthenticated && !authContainer.state.accessToken) {
            authContainer.refreshToken();
        }
    }, []);

    if (authContainer.state.accessToken == null) {
        return <Loader />
    }

    return (
        <div className={wrapperStyle}>
            <div className={pageStyle}>
                <div className={containerStyle}>
                    <Route
                        exact
                        path={[
                            "/v/apps/:applicationId",
                            "/v/apps/:applicationId/templates",
                            "/v/apps/:applicationId/analytics",
                            "/v/apps/:applicationId/analytics/track",
                            "/v/apps/:applicationId/analytics/activity",
                            "/v/apps/:applicationId/analytics/users",
                            "/v/apps/:applicationId/analytics/misses",
                            "/v/apps/:applicationId/analytics/report",
                            "/v/apps/:applicationId/analytics/sessions",
                            "/v/apps/:applicationId/analytics/live",
                            "/v/apps/:applicationId/participants",
                            "/v/apps/:applicationId/appSettings/interactionmodel",
                            "/v/apps/:applicationId/appSettings/nlp",
                            "/v/apps/:applicationId/appSettings/appInfo",
                            "/v/apps/:applicationId/appSettings/nlp/entities",
                            "/v/apps/:applicationId/appSettings/nlp/intents",
                            "/v/apps/:applicationId/appSettings/nlp/menu",
                            "/v/apps/:applicationId/appSettings/nlp/optiongroups",
                            "/v/apps/:applicationId/appSettings/nlp/genai",
                            "/v/apps/:applicationId/appSettings/deployments",
                            "/v/apps/:applicationId/appSettings/deployments/:environmentId",
                            "/v/apps/:applicationId/appSettings",
                            "/v/apps/:applicationId/simulator",
                            "/v/apps/:applicationId/modules",
                            "/v/apps/:applicationId/modules/discover",
                            "/v/apps/:applicationId/webhooks/:webhookFolderId?",
                            "/v/apps/:applicationId/webhooks/:webhookFolderId?",
                            "/v/apps/:applicationId/content/:applicationFeatureId",
                            "/v/:applicationFeatureId/welcomemessages/:contentItemId",
                            "/v/:applicationFeatureId/welcomemessages",
                            "/v/:applicationFeatureId/new/welcomemessage",
                            "/v/:applicationFeatureId/exitmessages/:contentItemId",
                            "/v/:applicationFeatureId/exitmessages",
                            "/v/:applicationFeatureId/new/exitmessage",
                            "/v/:applicationFeatureId/latestmessages/:contentItemId",
                            "/v/:applicationFeatureId/latestmessages",
                            "/v/:applicationFeatureId/new/latestmessage",
                            "/v/:applicationFeatureId/helpmessages/:contentItemId",
                            "/v/:applicationFeatureId/helpmessages",
                            "/v/:applicationFeatureId/new/helpmessage",
                            "/v/:applicationFeatureId/fallbackmessages/:contentItemId",
                            "/v/:applicationFeatureId/fallbackmessages",
                            "/v/:applicationFeatureId/new/fallbackmessage",
                            "/v/:applicationFeatureId/faqs/:contentItemId",
                            "/v/:applicationFeatureId/faqs",
                            "/v/:applicationFeatureId/new/faq",
                            "/v/:applicationFeatureId/numberranges/:contentItemId",
                            "/v/:applicationFeatureId/numberranges",
                            "/v/:applicationFeatureId/new/numberrange",
                            "/v/:applicationFeatureId/simplechoices/:contentItemId",
                            "/v/:applicationFeatureId/simplechoices",
                            "/v/:applicationFeatureId/new/simplechoice",
                            "/v/:applicationFeatureId/customRequests/:contentItemId",
                            "/v/:applicationFeatureId/customRequests",
                            "/v/:applicationFeatureId/new/customRequest",
                            "/v/:applicationFeatureId/content/:contentItemId",
                            "/v/:applicationFeatureId/content",
                            "/v/:applicationFeatureId/new/content"
                        ]}
                        component={ApplicationDashboard}
                    />

                    <Route exact
                        path={[
                            "/v/apps/:applicationId/templates/:appliedAppTemplateId",
                            "/v/apps/:applicationId/modules/applied/:appliedAppTemplateId",
                            "/v/apps/:applicationId/templates/new/:templateConfigurationId",
                            "/v/apps/:applicationId/modules/:templateConfigurationId/details"
                        ]} component={ApplicationAppliedTemplateManager} />

                    <Route exact path={[
                        "/v/apps/:applicationId/members/add"
                    ]} component={AddApplicationMember} />

                    <Route exact
                        path="/v/members/invite"
                        component={OrganizationMemberInvite} />

                    <Route exact
                        path={[
                            "/v",
                            "/",
                            "/v/members",
                            "/v/templates",
                            "/v/modules",
                            "/v/integrations",
                            "/v/orgSettings/apiKeys",
                            "/v/orgSettings/credentials",
                            "/v/orgSettings",
                            "/v/orgAnalytics",
                            "/v/orgAnalytics/applicationsPerformance",
                            "/v/orgAnalytics/ordering",]}
                        component={OrganizationDashboard} />
                </div>
            </div>

            <Route path="/v/:applicationFeatureId/new/welcomemessage" exact component={CreateWelcomeMessage} />
            <Route path="/v/:applicationFeatureId/welcomemessages/:welcomeMessageId" component={EditWelcomeMessage} />
            <Route path="/v/:applicationFeatureId/exitmessages/:exitMessageId" component={EditExitMessage} />
            <Route path="/v/:applicationFeatureId/new/exitmessage" exact component={CreateExitMessage} />
            <Route path="/v/:applicationFeatureId/new/faq" exact component={CreateQuestionAnswer} />
            <Route path="/v/:applicationFeatureId/faqs/:questionAnswerId" component={EditQuestionAnswer} />
            <Route path="/v/:applicationFeatureId/new/latestMessage" exact component={CreateLatestMessage} />
            <Route path="/v/:applicationFeatureId/latestMessages/:latestMessageId" component={EditLatestMessage} />
            <Route path="/v/:applicationFeatureId/helpmessages/:helpMessageId" component={EditHelpMessage} />
            <Route path="/v/:applicationFeatureId/new/helpmessage" exact component={CreateHelpMessage} />
            <Route path="/v/:applicationFeatureId/fallbackmessages/:fallbackMessageId" component={EditFallbackMessage} />
            <Route path="/v/:applicationFeatureId/new/fallbackmessage" exact component={CreateFallbackMessage} />
            <Route path="/v/:applicationFeatureId/new/numberRange" exact component={CreateNumberRange} />
            <Route path="/v/:applicationFeatureId/numberRanges/:numberRangeId" component={EditNumberRange} />
            <Route path="/v/:applicationFeatureId/new/simplechoice" exact component={CreateSimpleChoice} />
            <Route path="/v/:applicationFeatureId/simplechoices/:simpleChoiceId" component={EditSimpleChoices} />
            <Route path="/v/:applicationFeatureId/new/customRequest" exact component={CreateCustomRequest} />
            <Route path="/v/:applicationFeatureId/customRequests/:customRequestId" component={EditCustomRequest} />
            <Route path="/v/templates/add" exact component={CreateTemplateConfiguration} />
            <Route path="/v/modules/add" exact component={CreateTemplateConfiguration} />
            <Route path="/v/modules/edit/:templateConfigurationId" exact component={EditTemplateConfiguration} />
            <Route path="/v/templates/edit/:templateConfigurationId" exact component={EditTemplateConfiguration} />


            <Route path="/v/create-app" exact component={CreateApplication} />
            <Route path="/v/logout" exact component={Logout} />
            <NewTermsModal userContainer={userContainer} />
            <UrgentNotificationSplash />
        </div>
    );
}

const containerStyle = css`
    display:flex;
    flex-direction:row;
    max-height: 100%;
    height: 100%;
`;

const pageStyle = css`
    position:fixed;
    width: 100%;
    height: 100%;
`;

const wrapperStyle = css`
    height: 100%;
`;

export default Dashboard;
