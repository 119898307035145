
import React, { useState } from "react"
import { createContainer } from "unstated-next"
import * as voicifyApi from '../api';
import MenuItemModel from "../models/nlp/menu/MenuItemModel";
import IResult from "../models/result/IResult";
import { AugmentMenuRequest } from "../models/nlp/menu/AugmentMenuRequest";
import { VoicifyFormatModel } from "../models/nlp/menu/VoicifyFormatModel";

function useApplicationNlpMenuContainer() {
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState([] as string[]);
    const [menuItems, setMenuItems] = useState([]);

    const loadAllMenuItems = async (applicationId: string) => {
        try {
            setIsLoading(true);
            const result = await voicifyApi.getVoicifyMenuItems(applicationId);
            if (result.resultType == "Ok") {
                setMenuItems(result.data);
            }
            else if (result.errors != null && result.errors.length > 0) {
                setErrors([...result.errors]);
            }
            setIsLoading(false);
            return result;
        } catch (error) {
            setErrors([error]);
            setIsLoading(false);
        }
    };

    const updateMenuItems = async (applicationId: string, menuItems: MenuItemModel[]) => {
        try {
            setIsLoading(true);
            const result = await voicifyApi.updateVoicifyMenuItems(applicationId, menuItems);
            if (result.errors != null && result.errors.length > 0) {
                setErrors([...result.errors]);
            }
            setIsLoading(false);
            return result;
        } catch (error) {
            setErrors([error]);
            setIsLoading(false);
        }
    };

    const augmentMenu = async (request: AugmentMenuRequest) => {
        try {
            setIsLoading(true);

            const result = await voicifyApi.augmentMenu(request);
            if (result.errors != null && result.errors.length > 0) {
                setErrors([...result.errors]);
            }
            setIsLoading(false);
            return result;
        } catch (error) {
            setErrors([error]);
            setIsLoading(false);
        }
    };

    const addVoicifyMenuItem = async (applicationId: string, request: MenuItemModel): Promise<IResult<MenuItemModel>> => {
        try {
            setIsLoading(true);
            const result = await voicifyApi.addVoicifyMenuItem(applicationId, request);
            if (result.errors != null && result.errors.length > 0) {
                setErrors([...result.errors]);
            }
            setIsLoading(false);
            return result;
        } catch (error) {
            setErrors([error]);
            setIsLoading(false);
        }
    };

    const updateVoicifyMenuItem = async (applicationId: string, request: MenuItemModel): Promise<IResult<MenuItemModel>> => {
        try {
            setIsLoading(true);
            const result = await voicifyApi.updateVoicifyMenuItem(applicationId, request);
            if (result.errors != null && result.errors.length > 0) {
                setErrors([...result.errors]);
            }
            setIsLoading(false);
            return result;
        } catch (error) {
            setErrors([error]);
            setIsLoading(false);
        }
    };

    const getTransformedMenu = async (applicationId: string, posName: string, locationId: string): Promise<IResult<VoicifyFormatModel>> => {
        try {
            setIsLoading(true);
            const result = await voicifyApi.getTransformedMenu(applicationId, posName, locationId);
            if (result.errors != null && result.errors.length > 0) {
                setErrors([...result.errors]);
            }
            setIsLoading(false);
            return result;
        } catch (error) {
            setErrors([error]);
            setIsLoading(false);
        }
    };

    const deleteVoicifyMenuItem = async (applicationId: string, menuItemId: string): Promise<IResult<MenuItemModel>> => {
        try {
            setIsLoading(true);
            const result = await voicifyApi.deleteVoicifyMenuItem(applicationId, menuItemId);
            if (result.errors != null && result.errors.length > 0) {
                setErrors([...result.errors]);
            }
            setIsLoading(false);
            return result;
        } catch (error) {
            setErrors([error]);
            setIsLoading(false);
        }
    };

    const clearErrors = () => {
        setErrors([]);
    };

    return {
        updateMenuItems,
        loadAllMenuItems,
        menuItems,
        isLoading,
        errors,
        clearErrors,
        addVoicifyMenuItem,
        updateVoicifyMenuItem,
        deleteVoicifyMenuItem,
        augmentMenu,
        getTransformedMenu
    };
}

const ApplicationNlpMenuContainer = createContainer(useApplicationNlpMenuContainer);
export default ApplicationNlpMenuContainer;