import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import ApplicationBackupVersionsContainer from '../../../state/containers/ApplicationBackupVersionsContainer';
import SecondaryLoader from '../../../components/general/SecondaryLoader';
import { style_border_default } from '../../../constants/stylesValues';
import MemberContainer from '../../../state/containers/MemberContainer';
import ApplicationEnvironmentsContainer from '../../../state/containers/ApplicationEnvironmentsContainer';
import { color_colors_decline, color_colors_growth, color_colors_ocean, color_shades_dark, color_shades_darker, color_shades_darkest, color_text_default, color_text_light } from '../../../constants/colors';
import User from '../../../models/user/User';
import { css } from '@emotion/css';
import UserAvatar from '../../../components/general/UserAvatar';
import SmallOverflowMenu from '../../../components/general/overflowMenu/SmallOverflowMenu';
import ConfirmationDialog from '../../../components/structure/ConfirmationDialog';
import DeleteConfirmation from '../../featureContent/components/DeleteConfirmation';
import WarningConfirmation from '../../../components/general/WarningConfirmation';
import _ from 'lodash';
import moment from 'moment';
import PublishDetailsModal from './PublishDetailsModal';
import TooltipWrapper from '../../../components/general/TooltipWrapper';
import ApplicationBackupVersionModel from '../../../models/applications/api/backups/ApplicationBackupVersionModel';
import ApplicationEnvironmentPublishModel from '../../../models/applications/api/environments/ApplicationEnvironmentPublishModel';
import IGenericContentContainer from '../../../state/definitions/IGenericContentContainer';
const closeIcon = require("../../../content/images/remove-inset.svg");
const fullPublishIcon = require('../../../content/images/app-history-icons/full-publish.svg')
const unpublishIcon = require('../../../content/images/app-history-icons/unpublish.svg');
const errorIcon = require('../../../content/images/alert-circle.svg')
const partialPublishIcon = require('../../../content/images/app-history-icons/partial-publish.svg')
const backupCloudIcon = require('../../../content/images/app-history-icons/backupCloudIcon.svg')
interface PublishHistoryProps {
    backupsContainer: ApplicationBackupVersionsContainer
    memberContainer: MemberContainer
    environmentsContainer: ApplicationEnvironmentsContainer
    contentItemContainer: IGenericContentContainer<any, any, any, any>
    applicationId: string
    organizationId: string
    contentId?: string
    featureTypeId?: string
    onClose: () => void
}

interface HistoryLineItem {
    publishType: "Full Publish" | "Partial Publish" | "Full Backup" | "Partial Backup" | "Unpublish"
    name: string
    user: User
    createdDate: string
    processing: boolean
    id: string
    backupId: string
    archiveId: string
    processingText: "Publishing" | "Backing Up",
    error?: string
}

const RenameLabel = "Rename";
const DeleteLabel = "Delete from history";
const PublishLabel = "Publish this version";
const DownloadLabel = "Download";
const ViewDetailsLabel = "View publishing details";
const DATE_FORMAT = "YYYY-MM-DDThh:mm:ss";

const PublishHistory: React.FC<PublishHistoryProps> = ({ backupsContainer,
    memberContainer,
    environmentsContainer,
    applicationId,
    organizationId,
    onClose,
    contentItemContainer,
    contentId }) => {
    const [renameItem, setRenameItem] = useState<HistoryLineItem>(null);
    const [renameItemValue, setRenameItemValue] = useState<string>('');
    const [deletingItem, setDeletingItem] = useState<HistoryLineItem>(null);
    const [publishingItem, setPublishingItem] = useState<HistoryLineItem>(null);
    const [detailsItem, setDetailsItem] = useState<HistoryLineItem>(null);
    const [componentLoaded, setComponentLoaded] = useState(false);
    const [items, setItems] = useState([] as HistoryLineItem[]);
    const [latestFullPublish, setLatestFullPublish] = useState<string>('');
    const isLoading = !componentLoaded
        || backupsContainer.state.isLoading
        || environmentsContainer.state.isLoading
        || memberContainer.state.isLoading;

    useEffect(() => {
        const applicationHistoryLoad = async () => {
            const utcThirtyMinutesAgo = moment.utc().subtract(30, 'minutes');
            const backupsResult = await backupsContainer.loadBackupVersions(applicationId);
            let skip = 0;
            let publishResult = await environmentsContainer.loadPublishes(applicationId, skip, 50, false);
            let loadedPublishes = [...publishResult.data];
            while (publishResult && publishResult.data.length > 49) {
                skip = skip + 50;
                publishResult = await environmentsContainer.loadPublishes(applicationId, skip, 50, true);
                loadedPublishes = [...loadedPublishes, ...publishResult.data];
            }
            backupsResult.data?.forEach(b => {
                if (b?.applicationArchive?.stage && b.applicationArchive.stage != "Complete" && moment(b.applicationArchive.createdDate, DATE_FORMAT).isAfter(utcThirtyMinutesAgo))
                    backupsContainer.waitForBackup(b.id);
            });
            loadedPublishes?.forEach(p => {
                if (p?.stage && p.stage != "Complete" && moment(p.createdDate, DATE_FORMAT).isAfter(utcThirtyMinutesAgo))
                    environmentsContainer.waitForPublish(p.id);
            });
            const builtItems = buildApplicationHistoryItems(loadedPublishes, backupsResult.data);
            setItems(builtItems);
            setComponentLoaded(true);
        }
        const contentItemLoad = async () => {
            let skip = 0;
            let environments = environmentsContainer.state.environments;
            if (environments.length == 0) {
                var environmentResult = await environmentsContainer.loadEnvironments(applicationId);
                environments = environmentResult.data;
            }
            if (memberContainer.state.members.length == 0)
                await memberContainer.getMembers(organizationId);
            const envId = environments[0]?.id;
            let publishResult = await contentItemContainer.loadPublishHistory(contentId, envId, skip, 50);
            let loadedPublishes = [...publishResult.data];
            while (publishResult && publishResult.data.length > 49) {
                skip = skip + 50;
                publishResult = await contentItemContainer.loadPublishHistory(contentId, envId, skip, 50);
                loadedPublishes = [...loadedPublishes, ...publishResult.data];
            }

            const builtItems: HistoryLineItem[] = loadedPublishes.map(publish => {
                return {
                    publishType: publish?.publishEventType == "Publish" ? "Full Publish" : "Unpublish",
                    name: publish?.publishEventType == "Unpublish" ? publish.name ?? "Unpublished" : publish.applicationEnvironmentPublish?.applicationBackupVersion?.name ?? "Publish",
                    user: buildUser(publish.createdByUserId),
                    createdDate: publish?.applicationEnvironmentPublish?.createdDate ?? publish?.date,
                    processing: false,
                    id: publish.id,
                    backupId: publish?.applicationEnvironmentPublish?.applicationBackupVersion?.id,
                    archiveId: publish?.applicationEnvironmentPublish?.applicationBackupVersion?.applicationArchiveId,
                    processingText: "Publishing"
                }
            });
            setItems(builtItems);
            setComponentLoaded(true);
        }
        if (contentId) {
            contentItemLoad();
        } else {
            applicationHistoryLoad();
        }
    }, []);

    useEffect(() => {
        if (items?.length > 0) {
            const publishedDate = items?.filter((item) => item.publishType === "Full Publish")?.
                reduce((prevDateString, currItem) => {
                    const itemPublishDateString = currItem.createdDate;
                    if (!prevDateString)
                        return itemPublishDateString;
                    const itemPublishDate = moment(itemPublishDateString, DATE_FORMAT);
                    const prevPublishDate = moment(prevDateString, DATE_FORMAT);
                    const isLater = itemPublishDate.isAfter(prevPublishDate);
                    if (isLater)
                        return itemPublishDateString;
                    return prevDateString;
                }, "");
            setLatestFullPublish(publishedDate);
        }
    }, [items]);

    useEffect(() => {
        if (!contentId && componentLoaded) {
            const builtItems = buildApplicationHistoryItems(environmentsContainer.state.publishes, backupsContainer.state.backupVersions);
            setItems(builtItems);
            setComponentLoaded(true);
        }
        memberContainer.getMembers(organizationId);
    }, [environmentsContainer.state.publishes, backupsContainer.state.backupVersions]);

    useEffect(() => {
        memberContainer.getMembers(organizationId);
    }, [organizationId]);

    const buildUser = (userId: string) => {
        // If publish created user id is null then it was generated by internal Voicify logic 
        if (!userId)
            return { id: null, email: null, firstName: "AUTOMATED", lastName: "" };
        const user = memberContainer.state.members.find(m => m.userId === userId)
        return user ?? { id: null, email: null, firstName: "Unknown", lastName: "Member" };
    }

    const buildApplicationHistoryItems = (publishes: ApplicationEnvironmentPublishModel[], backups: ApplicationBackupVersionModel[]) => {
        const utcThirtyMinutesAgo = moment().subtract(30, "minutes");
        const backupsHistory: HistoryLineItem[] = backups.map(backup => {
            const complete = backup?.applicationArchive?.stage == "Complete";
            let isTimedOut = false;
            if (!complete) {
                isTimedOut = moment.utc(backup.applicationArchive?.createdDate, DATE_FORMAT).isBefore(utcThirtyMinutesAgo);
            }
            return {
                publishType: backup.applicationArchive?.archiveType == "FullApplication" ? "Full Backup" : "Partial Backup",
                name: backup.name,
                user: buildUser(backup.applicationArchive?.createdByUserId),
                createdDate: backup.createdDate,
                processing: !complete,
                id: backup.id,
                backupId: backup.id,
                archiveId: backup.applicationArchiveId,
                processingText: "Backing Up",
                error: isTimedOut ? "Backup timed out" : backup?.applicationArchive?.error ? backup.applicationArchive.error : null
            }
        });

        const publishesHistory: HistoryLineItem[] = publishes.map(publish => {
            const backup = backupsHistory.find(b => b.backupId === publish.applicationBackupVersionId);
            const complete = publish.stage == "Complete";
            let isTimedOut = false;

            if (!complete) {
                isTimedOut = moment.utc(publish.createdDate, DATE_FORMAT).isBefore(utcThirtyMinutesAgo);
            }
            return {
                publishType: backup?.publishType == "Full Backup" ? "Full Publish" : "Partial Publish",
                name: backup?.name ?? "Unknown",
                user: buildUser(publish.createdByUserId),
                createdDate: publish.createdDate,
                processing: !complete,
                id: publish.id,
                backupId: backup?.id,
                archiveId: backup?.archiveId,
                processingText: "Publishing",
                error: isTimedOut ? "Publish timed out" : publish?.error ? publish.error : null
            }
        });
        return _.sortBy(backupsHistory.filter(b => b.publishType == "Full Backup").concat(publishesHistory), (item) => item.createdDate).reverse();
    }
    const getItemIcon = (item: HistoryLineItem) => {
        if (item.error) {
            return errorIcon;
        }
        switch (item.publishType) {
            case "Full Publish":
                return fullPublishIcon;
            case "Partial Publish":
                return partialPublishIcon;
            case "Full Backup":
                return backupCloudIcon;
            case "Unpublish":
                return unpublishIcon;
        }
    }
    const handleOptionOnItem = async (item: HistoryLineItem, option: string) => {
        switch (option) {
            case RenameLabel:
                setRenameItem(item);
                setRenameItemValue(item.name); break;
            case DeleteLabel: setDeletingItem(item); break;
            case PublishLabel: setPublishingItem(item); break;
            case DownloadLabel: await backupsContainer.downloadArchive(item.archiveId); break;
            case ViewDetailsLabel: setDetailsItem(item); break;
            default: alert("Not implemented yet")
        }
    }
    const handleBlurRename = () => {
        if (renameItemValue) {
            backupsContainer.updateApplicationBackupVersion(renameItem.backupId, { name: renameItemValue });
            setRenameItem(null);
        }
        setRenameItem(null);
    }
    const handleKeyDownRename = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            handleBlurRename();
        }
    }
    const handleDeleteItem = async () => {
        if (deletingItem.publishType === "Full Backup" || deletingItem.publishType === "Partial Backup") {
            await backupsContainer.deleteBackupVersion(deletingItem.backupId);
        }
        // note: not allowing deleting publish events
        setDeletingItem(null);
    }
    const handlePublishItem = async () => {
        var publishResult = await environmentsContainer.queuePublishBackup(environmentsContainer.findPrimaryEnvironment(applicationId).id, publishingItem.backupId);
        if (publishResult.resultType == "Ok") {
            environmentsContainer.waitForPublish(publishResult.data.id);
        }
        setPublishingItem(null);
    }
    const getMenuOptions = (item: HistoryLineItem) => {
        switch (item.publishType) {
            case "Full Publish":
            case "Partial Publish":
                return item.backupId ? [{ label: DownloadLabel }, { label: RenameLabel }] : [];
            case "Full Backup":
            case "Partial Backup":
                return [{ label: DownloadLabel }, { label: PublishLabel }, { label: RenameLabel }, { label: DeleteLabel, isDestructive: true }]
        }
        return []
    }

    const getCircleClassName = (item: HistoryLineItem) => {
        const publishedDate = moment(item.createdDate, DATE_FORMAT);
        if (item.publishType === "Partial Publish" && publishedDate.isAfter(moment(latestFullPublish, DATE_FORMAT))) {
            return "latest-partial-publish-circle"
        }
        if (item.publishType === "Full Publish" && publishedDate.isSame(moment(latestFullPublish, DATE_FORMAT))) {
            return "latest-full-publish-circle"
        }
        switch (item.publishType) {
            case "Full Publish":
            case "Partial Publish":
                return "publish-circle"
            case "Full Backup":
            case "Partial Backup":
                return "backup-circle";
        }
        return ''
    }

    return (
        <div>
            <PanelHeader>
                <TimelineHeader>Timeline</TimelineHeader>
                <CloseButton onClick={() => onClose()}><span>Close</span> <img src={closeIcon} alt="close" /></CloseButton>
            </PanelHeader>
            {isLoading && items.length < 1 && <SecondaryLoader className={spinnerStyle} />}
            {!isLoading && items.length == 0 &&
                <>
                    <EmptyLabelHeader>No Timeline Entries Yet</EmptyLabelHeader>
                    <EmptyLabel>Saving a backup or publishing content will create a copy of your app that can be accessed here</EmptyLabel>
                </>}
            {items.length != 0 &&
                <Timeline>
                    {items.map(historyItem => {
                        return (<TimelineItem key={historyItem.id} className={`timeline-item ${historyItem.publishType == "Partial Publish" || historyItem.publishType == "Partial Backup" ? "partial" : ""}`}>
                            <div className="item-header">
                                <div className="partial-line" />
                                <span className={getCircleClassName(historyItem)} />
                                <p className="item-name">
                                    {historyItem.error ?
                                        <TooltipWrapper place="right" text={historyItem.error}>
                                            <img src={getItemIcon(historyItem)} alt="history item" />
                                        </TooltipWrapper>
                                        :
                                        <img src={getItemIcon(historyItem)} alt="history item" />
                                    }
                                    {renameItem?.id != null && renameItem?.id == historyItem?.id
                                        ?
                                        <input value={renameItemValue} type="text" autoFocus onFocus={(e) => e.target.select()} onKeyDown={handleKeyDownRename} onBlur={(e) => handleBlurRename()} onChange={(e) => setRenameItemValue(e.target.value)} />
                                        :
                                        <span className={historyItem.error ? "error" : historyItem.processing ? "processing" : ""}>
                                            {historyItem.error ? "Error" : historyItem.processing ? historyItem.processingText : historyItem.name}
                                        </span>
                                    }
                                </p>
                                {(!historyItem.error && !historyItem.processing && getMenuOptions(historyItem).length > 0) &&
                                    <SmallOverflowMenu menuClassName={overflowOptionsStyle}
                                        optionClassName={overflowOptionStyle}
                                        buttonClassName={overflowButtonStyle}
                                        className={overflowStyle}
                                        options={getMenuOptions(historyItem)}
                                        onOptionSelected={(o) => handleOptionOnItem(historyItem, o)}
                                        topOptionStyle={topOptionStyle}
                                        bottomOptionStyle={bottomOptionStyle}
                                    />
                                }
                            </div>
                            <p className="item-date">{moment.utc(historyItem.createdDate, DATE_FORMAT).local().format("MM/DD/YYYY hh:mm a")}</p>
                            <div className="item-user">
                                {historyItem.user.id &&
                                    <UserAvatar noBackground size={16}
                                        src={historyItem.user.imageUrl}
                                        firstName={historyItem.user.firstName}
                                        lastName={historyItem.user.lastName}
                                    />
                                }
                                <span>{historyItem.user.firstName} {historyItem.user.lastName}</span>
                            </div>
                        </TimelineItem>);
                    })}
                    {(isLoading && items.length > 10) && <SecondaryLoader className={spinnerStyle} />}
                </Timeline>

            }
            {deletingItem != null &&
                <ConfirmationDialog title={`Delete From History`}
                    deleteText="Yes, Delete"
                    isLoading={backupsContainer.state.isDeleting}
                    onClose={() => setDeletingItem(null)}
                    onConfirm={() => handleDeleteItem()}>
                    <DeleteConfirmation itemLabel={`${deletingItem.name}?`} />
                </ConfirmationDialog>}
            {publishingItem != null &&
                <ConfirmationDialog title={`Publish Previous Version`}
                    deleteText="Publish"
                    warning
                    isLoading={environmentsContainer.state.isCreating}
                    onClose={() => setPublishingItem(null)}
                    onConfirm={() => handlePublishItem()}>
                    <WarningConfirmation text={`Restoring ${publishingItem.name} will override any previously published changes.`} />
                </ConfirmationDialog>}
            {detailsItem != null &&
                <PublishDetailsModal applicationId={applicationId}
                    publishId={detailsItem.id}
                    backupsContainer={backupsContainer}
                    environmentsContainer={environmentsContainer}
                    onClose={() => setDetailsItem(null)} />}
        </div>
    );
}

const overflowStyle = css`
    margin-right: 0;
    margin-left: auto;
`;

const overflowButtonStyle = css`
    border: none;
    width: 24px;
    height: 24px;
    .circle {
        width: 4px;
        height: 4px;
        border-color: ${color_text_default};
    }
    &:hover, &.active { 
        border: 1px solid ${color_colors_ocean};
        .circle {
            width: 4px;
            height: 4px;
            border-color: ${color_colors_ocean};
        }
    }
`

const overflowOptionsStyle = css`
    width: 156px;
    margin-left: -122px;
    border-radius: 4px;
`

const topOptionStyle = css`
    border-top-right-radius: 4px;
    border-top-left-radius: 4px; 
`

const bottomOptionStyle = css`
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
`

const overflowOptionStyle = css`
    height: 32px;
    border-radius: none;
    p {
        font-size: 12px;
        line-height: 16px;
        margin: 0 11px;
    }
`
const EmptyLabelHeader = styled.p`
    margin: 8px 24px 8px;
    font-style: italic;
    font-size: 14px;
    color: ${color_shades_darkest};
    
`
const EmptyLabel = styled.p`
    margin: 8px 24px;
    font-size: 12px;
    color: ${color_shades_darker};
`

const PanelHeader = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 12px;
`

const spinnerStyle = css`
    flex:1;
    .spinner > div {
            background: ${color_colors_ocean};
    }
`

const TimelineHeader = styled.p`
    flex: 1;
    color: ${color_text_light};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    margin: 16px 24px 8px;
`

const Timeline = styled.ul`
    list-style: none;
    border-left: ${style_border_default};
    margin: 12px 24px 0 24px;
    padding: 12px 0 250px 0;
`

const TimelineItem = styled.li`
    padding-left: 16px;
    margin-bottom: 24px;
    &:first-child {
        margin-top: -24px;
    }
    &.partial {
        margin-left: 24px;
        .partial-line {
            display: block;
            width: 12px;
            border-bottom: 1px dashed ${color_shades_dark};
            margin-left: -40px;
            margin-right: 24px;
        }
        .latest-partial-publish-circle {
            width: 6px;
            height: 6px;
            border-radius: 50%;
            border: 1px solid ${color_colors_growth};
            background: transparent;
            margin-left: -19px;
            margin-right: 12px;
            box-sizing: border-box;
        }
        .item-name {
            >span {
                max-width: 154px;
            }
        }
    }
    .partial-line {
        display: none;
    }
    .backup-circle {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        border: ${style_border_default};
        margin-left: -19px;
        margin-right: 12px;
        background: white;
        box-sizing: border-box;
    }
    .publish-circle {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: ${color_colors_growth};
        margin-left: -19px;
        margin-right: 12px;
        box-sizing: border-box;
        &.latest {
            background: ${color_colors_growth};
        }
    }
    .latest-full-publish-circle {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: ${color_colors_growth};
        margin-left: -19px;
        margin-right: 12px;
        display: inline-block;
        box-sizing: border-box;
    }
    .item-header {
        display: flex;
        align-items: center;
    }
    .item-name {
        display: flex;
        align-items: center;
        >span {
            margin-left: 8px;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            color: ${color_text_default};            
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 164px;
            &.processing {
                color: ${color_colors_growth};
            }
            &.processing:after {
                animation: dots steps(1,end) 1s infinite;
                content: '';
            }
            &.error {
                color: ${color_colors_decline};
            }
        }
        >input {
            margin-left: 8px;
            font-size: 14px;
            line-height:20px;
        }
    }
    .item-date {
        color: ${color_text_light};
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 16px;
        margin-top: 6px;
    }
    .item-user {
        display: flex;
        align-items: center;
        color: ${color_text_default};
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        margin-top: 4px;
        >span {
            margin-left: 8px;
        }
    }
    ~ .timeline-item {
        &.partial {
            .publish-circle {
                background: transparent;
                border: ${style_border_default};
            }
        }
        .publish-circle {
            background: ${color_shades_dark};
        }
    }    
    @keyframes dots {
        0%   { content: ''; }
        25%  { content: ' .'; }
        50%  { content: ' ..'; }
        75%  { content: ' ...'; }
        100% { content: ''; }
    }
`;

const CloseButton = styled.button`
    background: none;
    border: none;
    display: flex;
    align-items: center;
    margin-right: 24px;
    margin-left: auto;
    margin-top: 10px;
    cursor: pointer;
    padding: 0;
    span {
        font-family: Muli;
        text-align: right;
        color: ${color_text_default};
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        margin-right: 4px;
    }
`

export default PublishHistory;