import IResult from "../../models/result/IResult";
import { sendDelete, sendGet, sendPost, sendPut } from "./generic";
import { APPLICATION_MENU_ITEM, getNlpModulesApiURL, VOICIFY_MENU } from "../../constants/Urls";
import MenuItemModel from "../../models/nlp/menu/MenuItemModel";
import UpdateMenuRequest from "../../models/nlp/menu/UpdateMenuRequest";
import { SyncMenusRequest } from "../../models/nlp/menu/SyncMenusRequest";
import { AugmentMenuRequest } from "../../models/nlp/menu/AugmentMenuRequest";
import { VoicifyFormatModel } from "../../models/nlp/menu/VoicifyFormatModel";

export const addVoicifyMenuItem = (applicationId: string, request: MenuItemModel): Promise<IResult<MenuItemModel>> => {
    return sendPost(`${APPLICATION_MENU_ITEM}/${applicationId}`, request);
};

export const updateVoicifyMenuItem = (applicationId: string, request: MenuItemModel): Promise<IResult<MenuItemModel>> => {
    return sendPut(`${APPLICATION_MENU_ITEM}/${applicationId}`, request);
};

export const deleteVoicifyMenuItem = (applicationId: string, menuItemId: string): Promise<IResult<MenuItemModel>> => {
    return sendDelete(`${APPLICATION_MENU_ITEM}/${applicationId}?menuItemId=${menuItemId}`);
};

export const getVoicifyMenuItems = (applicationId: string, menuItemId?: string): Promise<IResult<MenuItemModel[]>> => {
    let url = `${APPLICATION_MENU_ITEM}/${applicationId}`;
    if (menuItemId) {
        url += `/?menuItemId=${menuItemId}`
    }
    return sendGet(url);
};

export const updateVoicifyMenuItems = (applicationId: string, request: MenuItemModel[]): Promise<IResult<MenuItemModel[]>> => {
    return sendPut(`${APPLICATION_MENU_ITEM}/${applicationId}/setAll`, request);
};

export const augmentMenu = (request: AugmentMenuRequest): Promise<IResult<string>> => {
    return sendPost(`${VOICIFY_MENU}/augmentMenu`, request, getNlpModulesApiURL());
};

export const initiateMenuUpdate = (request: UpdateMenuRequest): Promise<IResult<any>> => {
    return sendPost(`${VOICIFY_MENU}/updateMenu`, request, getNlpModulesApiURL());
}

export const syncMenus = (request: SyncMenusRequest): Promise<IResult<string>> => {
    return sendPost(`${VOICIFY_MENU}/syncMenus`, request, getNlpModulesApiURL());
}

export const getTransformedMenu = (applicationId: string, posName: string, locationId: string): Promise<IResult<VoicifyFormatModel>> => {
    return sendGet(`${VOICIFY_MENU}/transformedMenu/${applicationId}?posName=${posName}&locationId=${locationId}`, getNlpModulesApiURL());
};
