import { useEffect, useState } from "react";
import { createContainer } from "unstated-next";
import { VOICIFY_SPARK_DEPLOYMENT_STATE } from "../constants/Keys";
import * as voicifyApi from '../api';
import moment from "moment";
import ApplicationEnvironmentModel from "../models/applications/api/environments/ApplicationEnvironmentModel";

export type TrainingState = "training" | "trained" | "error";

interface SparkDeploymentState {
    [appId: string]: {
        appliedTemplateSyncId?: string,
        lastSaveDateTime: Date,
        lastDeploymentDateTime: Date,
        orgId: string
    }
}

const TIMEOUT_MINUTES = 25;

function useSparkDeploymentContainer() {

    const [currentOrgId, setCurrentOrgId] = useState<string>("");
    const [deploymentState, setDeploymentState] = useState<SparkDeploymentState>(null);
    const [environments, setEnvironments] = useState<ApplicationEnvironmentModel[]>([]);
    const [pollingTrigger, setPollingTrigger] = useState(false);
    const [polling, setPolling] = useState(false);

    useEffect(() => {
        setDeploymentState(getStoredState());
        setPolling(true);
        setPollingTrigger(!pollingTrigger);
    }, []);

    useEffect(() => {
        if (!polling) return;
        pollForUnfinishedDeployments();
    }, [pollingTrigger, polling]);

    const getStoredState = (): SparkDeploymentState => {
        let storedState: SparkDeploymentState = {};
        const storedTrainingState = localStorage.getItem(VOICIFY_SPARK_DEPLOYMENT_STATE);
        if (storedTrainingState)
            storedState = JSON.parse(storedTrainingState);
        return storedState;
    };

    const storeState = (state: SparkDeploymentState) => {
        const json = JSON.stringify(state);
        localStorage.setItem(VOICIFY_SPARK_DEPLOYMENT_STATE, json);
    };

    const pollForApplication = async (appId: string, orgId: string, appliedTemplateSyncId?: string) => {
        const newState = { ...deploymentState };
        if (appId in newState) {
            newState[appId].lastSaveDateTime = new Date();
        } else {
            if (appliedTemplateSyncId) {
                newState[appId] = {
                    appliedTemplateSyncId: appliedTemplateSyncId,
                    lastSaveDateTime: new Date(),
                    lastDeploymentDateTime: null,
                    orgId: orgId
                }
            } else {
                newState[appId] = {
                    lastSaveDateTime: new Date(),
                    lastDeploymentDateTime: null,
                    orgId: orgId
                }
            }
        }
        setDeploymentState(newState);
    };

    const getAppDeploymentState = (appId: string): TrainingState => {
        if (!deploymentState || !(appId in deploymentState))
            return "trained";
        const lastSave = moment(deploymentState[appId].lastSaveDateTime);
        if (!deploymentState[appId].lastDeploymentDateTime)
            return "training";
        const lastDeployment = moment(deploymentState[appId].lastDeploymentDateTime);
        if (lastSave.isAfter(lastDeployment)) {
            if (moment().diff(lastSave, 'minutes') > TIMEOUT_MINUTES)
                return "error";
            return "training";
        }
        return "trained";
    };

    const getAppIdsToPoll = (): string[] => {
        if (!deploymentState)
            return [];
        const appIds = Object.keys(deploymentState);
        return appIds.filter(appId => {
            const st = getAppDeploymentState(appId);
            if (st === "training")
                return true;
            return false;
        });
    };

    const pollForUnfinishedDeployments = async () => {
        const newDeploymentState = { ...deploymentState };
        const appIds = getAppIdsToPoll();
        let env: ApplicationEnvironmentModel = null;
        const newEnvs = [...environments];
        for (const appId of appIds) {
            const appliedTemplateSyncId = newDeploymentState[appId].appliedTemplateSyncId;
            // find env for that app
            env = newEnvs.find(e => e.applicationId === appId && !e.isDraft && !e.isCustom);
            if (!env) {
                const environmentResults = await voicifyApi.getApplicationEnvironments(appId);
                if (environmentResults.resultType == "Ok") {
                    newEnvs.push(...environmentResults.data);
                    env = environmentResults.data.find(e => e.applicationId === appId && !e.isDraft && !e.isCustom);
                }
            }
            if (!env) {
                // something is wrong, we can't find the environment
                continue;
            }
            if (appliedTemplateSyncId) {
                const result = await voicifyApi.getAppliedTemplateSync(appliedTemplateSyncId);
                if (result.resultType == "Ok") {
                    const sync = result.data;
                    switch (sync.stage) {
                        case ("Processing"):
                            break;
                        case ("Queued"):
                            break;
                        case ("Complete"):
                            newDeploymentState[appId].lastDeploymentDateTime = new Date();
                    }
                }
            } else {
                const publishesResult = await voicifyApi.getEnvironmentPublishes(env.id, 0, 50);
                if (publishesResult.resultType == "Ok") {
                    const publishes = publishesResult.data;
                    // get last publish that is complete
                    const lastPublish = publishes.find(p => p.stage === "Complete");
                    if (lastPublish) {
                        // update the deployment state with created date of last publish
                        newDeploymentState[appId].lastDeploymentDateTime = new Date(lastPublish.createdDate);
                    }
                }
            }

        }
        setDeploymentState(newDeploymentState);
        setEnvironments(newEnvs);
        storeState(newDeploymentState);
        await new Promise(r => setTimeout(r, 7500));
        setPollingTrigger(!pollingTrigger);
    };

    return {
        setCurrentOrgId,
        pollForApplication,
        getAppDeploymentState,
        deploymentState,
    }

};

const SparkDeploymentContainer = createContainer(useSparkDeploymentContainer);
export default SparkDeploymentContainer;
