import { useState } from "react";
import * as voicifyApi from '../api';
import { createContainer } from "unstated-next";

function useApplicationNlpGenAIContainer() {
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState([] as string[]);
    const [isSavedSuccessfully, setIsSavedSuccessfully] = useState(false);

    const saveGenerativeAIAssistantPrompt = async (applicationId: string, generativeAIAssistantPrompt: string) => {
        try {
            if (!generativeAIAssistantPrompt) {
                setErrors(["Prompt cannot be empty"]);
                return;
            }
            setIsLoading(true);
            const result = await voicifyApi.updateGenerativeAIAssistantPrompt(applicationId, generativeAIAssistantPrompt);
            if (result.resultType != "Ok" && result.errors != null && result.errors.length > 0) {
                setErrors(result.errors);
                setIsSavedSuccessfully(false);
            }
            else if (result.resultType != "Ok") {
                setErrors(["An error occurred while saving the prompt"]);
                setIsSavedSuccessfully(false);
            }
            setIsSavedSuccessfully(result.resultType == "Ok");
            setIsLoading(false);
            return result;
        } catch (error) {
            setErrors([error]);
            setIsLoading(false);
            setIsSavedSuccessfully(false);
        }
    };


    return {
        isLoading,
        errors,
        saveGenerativeAIAssistantPrompt,
        isSavedSuccessfully,
    };
}

const ApplicationNlpGenAIContainer = createContainer(useApplicationNlpGenAIContainer);
export default ApplicationNlpGenAIContainer;