import React, { useEffect, useState } from "react";
import { InteractionModelFormData, InteractionModelFormProps } from "./InteractionModelForm";
import { FormikProps } from "formik";
import ApplicationIntentsEditor from "./ApplicationIntentsEditor";
import ApplicationNlpEntitiesEditor from "./ApplicationNlpEntitiesEditor";
import InteractionModelContainer from "../../../hooks/InteractionModelContainer";
import ApplicationNlpEntityContainer from "../../../hooks/ApplicationNlpEntityContainer";
import InteractionModelUiStateContainer from "../../../hooks/InteractionModelUiStateContainer";
import PageError from "../../../components/general/PageError";
import NlpSaveChangesModal from "./NlpSaveChangesModal";
import CustomScrollbars from "../../../components/structure/CustomScrollbars";
import useWindowSize from "../../../hooks/UseWindowSize";
import VoicifyMenuItemsEditor from "./VoicifyMenuItemsEditor";
import ApplicationNlpMenuContainer from "../../../hooks/ApplicationNlpMenuContainer";
import EditedMenuItemModel from "../../../models/nlp/menu/EditedMenuItemModel";
import VoicifyGroupsEditor from "./VoicifyGroupsEditor";
import { v4 as uuidv4 } from 'uuid';
import { MenuItemOptionGroup } from "../../../models/nlp/menu/MenuItemOptionGroup";
import { MenuItemOption } from "../../../models/nlp/menu/MenuItemOption";
import EditedMenuItemOptionGroupModel from "../../../models/nlp/menu/EditedMenuItemOptionGroupModel";
import MenuItemModel from "../../../models/nlp/menu/MenuItemModel";
import EditedMenuItemSizeGroupModel from "../../../models/nlp/menu/EditedMenuItemSizeGroupModel";
import { MenuItemSizeGroup } from "../../../models/nlp/menu/MenuItemSizeGroup";
import { MenuItemSize } from "../../../models/nlp/menu/MenuItemSize";
import GenAI from "./GenAI";
import ApplicationNlpGenAIContainer from "../../../hooks/ApplicationNlpGenAIContainer";

export interface Matches {
    [groupId: string]: string[] // list of menu item ID's;
};

const InteractionModelEditorWrapper = (props: InteractionModelFormProps & FormikProps<InteractionModelFormData>) => {
    const [showSaveChangesModal, setShowSaveChangesModal] = useState(false);
    const [reformattedMenuItems, setReformattedMenuItems] = useState([] as EditedMenuItemModel[]);

    const [selectedGroup, setSelectedGroup] = useState<EditedMenuItemOptionGroupModel | EditedMenuItemSizeGroupModel>(null);
    const [selectedMenuItem, setSelectedMenuItem] = useState<EditedMenuItemModel>();
    //option groups
    const [areOptionGroups, setAreOptionGroups] = useState(false);
    const [latestChangedOptionGroup, setLatestChangedOptionGroup] = useState<MenuItemOptionGroup>(null);
    const [latestOptionGroupToReplace, setLatestOptionGroupToReplace] = useState<EditedMenuItemOptionGroupModel>(null);
    //size groups
    const [areSizeGroups, setAreSizeGroups] = useState(false);
    const [latestChangedSizeGroup, setLatestChangedSizeGroup] = useState<MenuItemSizeGroup>(null);
    const [latestSizeGroupToReplace, setLatestSizeGroupToReplace] = useState<EditedMenuItemSizeGroupModel>(null);

    const interactionModelContainer = InteractionModelContainer.useContainer();
    const uiStateContainer = InteractionModelUiStateContainer.useContainer();
    const applicationNlpEntityContainer = ApplicationNlpEntityContainer.useContainer();
    const applicationNlpMenuContainer = ApplicationNlpMenuContainer.useContainer();
    const applicationNlpGenAIContainer = ApplicationNlpGenAIContainer.useContainer();

    const windowSize = useWindowSize();

    const appName = props.appContainer?.state?.currentApp?.name;
    const systemPrompt = props.appContainer?.state?.currentApp?.generativeAIAssistantPrompt;

    useEffect(() => {
        applicationNlpMenuContainer.loadAllMenuItems(props.applicationId);
    }, []);

    useEffect(() => {
        const formattedMenuItems: EditedMenuItemModel[] = applicationNlpMenuContainer.menuItems.map((menuItem) => {
            return { menuItem: menuItem }
        });
        setReformattedMenuItems(formattedMenuItems);
        interactionModelContainer.loadGroups(formattedMenuItems, uiStateContainer.menuEditorView);
        const thereAreOptionGroups = formattedMenuItems?.some((menuItem) => {
            return menuItem?.menuItem?.optionGroups?.length > 0
        });
        const thereAreSizeGroups = formattedMenuItems?.some((menuItem) => {
            return menuItem?.menuItem?.sizeGroups?.length > 0
        });
        setAreOptionGroups(thereAreOptionGroups);
        setAreSizeGroups(thereAreSizeGroups);
    }, [applicationNlpMenuContainer.menuItems]);

    useEffect(() => {
        if (latestChangedOptionGroup) {
            performGroupChange(latestChangedOptionGroup, latestOptionGroupToReplace);
        } else {
            interactionModelContainer.loadGroups(reformattedMenuItems, uiStateContainer.menuEditorView);
        }
        if (latestChangedSizeGroup) {
            performGroupChange(latestChangedSizeGroup, latestSizeGroupToReplace);
        } else {
            interactionModelContainer.loadGroups(reformattedMenuItems, uiStateContainer.menuEditorView);
        }
    }, [uiStateContainer.menuEditorView])

    useEffect(() => {
        uiStateContainer.setIsFormDirty(props.dirty);
    }, [props.dirty]);

    useEffect(() => {
        if (location.pathname.includes("/nlp/entities")) {
            uiStateContainer.setNluTab("Entities");
        }
        else if (location.pathname.includes("/nlp/intents")) {
            uiStateContainer.setNluTab("Intents");
        }
        else if (location.pathname.includes("/nlp/menu")) {
            uiStateContainer.setNluTab("Menu");
        }
        else if (location.pathname.includes("/nlp/genai")) {
            uiStateContainer.setNluTab("Gen AI");
        }
    }, [location.pathname]);

    useEffect(() => {
        uiStateContainer.setErrors(props.errors);
    }, [props.errors]);

    useEffect(() => {
        if (uiStateContainer.triggerSaveChanges && props.values.interactionModel && !(interactionModelContainer.isLoading || applicationNlpEntityContainer.isLoading)) {
            if (latestChangedOptionGroup) {
                performGroupChange(latestChangedOptionGroup, latestOptionGroupToReplace);
            }
            if (latestChangedSizeGroup) {
                performGroupChange(latestChangedSizeGroup, latestSizeGroupToReplace);
            }
            setShowSaveChangesModal(true);
            uiStateContainer.setTriggerSaveChanges(false);
        };
    }, [uiStateContainer.triggerSaveChanges]);

    const getAllOptionGroups = (item: MenuItemModel | MenuItemOption | MenuItemSize): MenuItemOptionGroup[] => {
        const optionGroupsList: MenuItemOptionGroup[] = [...(item.optionGroups ?? [])];
        for (const optionGroup of item.optionGroups) {
            for (const option of optionGroup?.options) {
                if (option?.optionGroups?.length > 0) {
                    optionGroupsList.push(...getAllOptionGroups(option));
                };
            };
        };
        if ('sizeGroups' in item) {
            for (const sizeGroup of item.sizeGroups) {
                for (const size of sizeGroup?.sizes) {
                    if (size?.optionGroups?.length > 0) {
                        optionGroupsList.push(...getAllOptionGroups(size));
                    };
                }
            };
        }
        return optionGroupsList;
    };

    const sortOptionsOrSizes = (sizesOrOptions: (MenuItemOption | MenuItemSize)[]) => {
        sizesOrOptions?.sort((a, b) => {
            if (!a || !a.name) {
                return -1;
            }
            if (!b || !b.name) {
                return 1;
            }
            const key1 = optionKey(a);
            const key2 = optionKey(b);
            const nameA = key1.toLowerCase();
            const nameB = key2.toLowerCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
    }

    const findGroupsToUpdate = (
        groups: (MenuItemOptionGroup | MenuItemSizeGroup)[],
        itemToReplace: (EditedMenuItemOptionGroupModel | EditedMenuItemSizeGroupModel | undefined),
        allToUpdate: (MenuItemOptionGroup | MenuItemSizeGroup)[]
    ) => {
        if (groups) {
            for (const group of groups) {
                if ('editId' in group && (itemToReplace ? itemToReplace.editId : selectedGroup.editId) === group.editId) {
                    allToUpdate.push(group);
                }
                if ('options' in group && group.options) {
                    for (const option of group.options) {
                        findGroupsToUpdate(option.optionGroups || [], itemToReplace, allToUpdate);
                    }
                }
            }
        }
    };

    const performGroupChange = (changedGroup: (MenuItemOptionGroup | MenuItemSizeGroup), groupToReplace?: (EditedMenuItemOptionGroupModel | EditedMenuItemSizeGroupModel)) => {
        const editedMenuItemsCopy = JSON.parse(JSON.stringify(interactionModelContainer.editedMenuItems));
        let matchingMenuItemIds: Matches;
        if ('options' in changedGroup) {
            matchingMenuItemIds = interactionModelContainer.optionGroupMatches.find(
                (match) => match[groupToReplace ? groupToReplace.editId : selectedGroup.editId]
            );
        } else if ('sizes' in changedGroup) {
            matchingMenuItemIds = interactionModelContainer.sizeGroupMatches.find(
                (match) => match[groupToReplace ? groupToReplace.editId : selectedGroup.editId]
            );
        }
        if (matchingMenuItemIds) {
            for (const menuItemId of matchingMenuItemIds[groupToReplace ? groupToReplace.editId : selectedGroup.editId]) {
                // for each menu item that has the selected group:
                const menuItemToUpdate: EditedMenuItemModel = editedMenuItemsCopy.find((menuItem: EditedMenuItemModel) => menuItem.menuItem.id === menuItemId)
                if (menuItemToUpdate) {
                    let groupsToUpdate: MenuItemOptionGroup[] | MenuItemSizeGroup[] = [];
                    if (uiStateContainer.menuEditorView === "Option Groups" && menuItemToUpdate.menuItem.optionGroups.length) {
                        if (groupToReplace) {
                            findGroupsToUpdate(menuItemToUpdate.menuItem.optionGroups, groupToReplace, groupsToUpdate);
                        } else {
                            const allOptionGroups: MenuItemOptionGroup[] = [];
                            for (const sizeGroups of menuItemToUpdate?.menuItem?.sizeGroups) {
                                for (const sizeGroupSize of sizeGroups?.sizes) {
                                    if (sizeGroupSize?.optionGroups?.length) {
                                        allOptionGroups.push(...sizeGroupSize?.optionGroups);
                                    }
                                }
                            }
                            menuItemToUpdate.menuItem.optionGroups.push(...allOptionGroups);
                            findGroupsToUpdate(menuItemToUpdate.menuItem.optionGroups, null, groupsToUpdate);
                        }
                    }
                    // for optionGroups within size
                    if (uiStateContainer.menuEditorView === "Option Groups" && !menuItemToUpdate.menuItem.optionGroups.length) {
                        for (const sizeGroups of menuItemToUpdate.menuItem.sizeGroups) {
                            for (const sizeGroupSize of sizeGroups.sizes) {
                                if (groupToReplace) {
                                    findGroupsToUpdate(sizeGroupSize.optionGroups, groupToReplace, groupsToUpdate);
                                } else {
                                    findGroupsToUpdate(sizeGroupSize.optionGroups, null, groupsToUpdate);
                                }
                            }
                        }
                    }
                    if (uiStateContainer.menuEditorView === "Size Groups") {
                        if (groupToReplace) {
                            findGroupsToUpdate(menuItemToUpdate.menuItem.sizeGroups, groupToReplace, groupsToUpdate);
                        } else {
                            findGroupsToUpdate(menuItemToUpdate.menuItem.sizeGroups, null, groupsToUpdate);
                        }
                    }
                    if (groupsToUpdate?.length) {
                        for (const groupToUpdate of groupsToUpdate) {
                            if (groupToUpdate) {
                                menuItemToUpdate.isModified = true;
                                // update options or sizes
                                const excludeProperties = ['editId', 'referenceId', 'id', 'parentId', 'options', 'sizes'];
                                Object.keys(changedGroup).forEach((property) => {
                                    if (!excludeProperties.includes(property)) {
                                        groupToUpdate[property] = changedGroup[property];
                                    }
                                    // remove properties that are not in changedGroup
                                    Object.keys(groupToUpdate).forEach((prop) => {
                                        if (!Object.keys(changedGroup).includes(prop)) {
                                            delete groupToUpdate[prop];
                                        }
                                    });
                                });
                                const optionsOrSizesToRemove = [];
                                let groupToUpdateOptionsOrSizes: MenuItemOption[] | MenuItemSize[] = [];
                                let changedGroupOptionsOrSizes: MenuItemOption[] | MenuItemSize[] = [];
                                if ('options' in groupToUpdate && 'options' in changedGroup) {
                                    groupToUpdateOptionsOrSizes = groupToUpdate.options;
                                    changedGroupOptionsOrSizes = changedGroup.options;
                                } else if ('sizes' in groupToUpdate && 'sizes' in changedGroup) {
                                    groupToUpdateOptionsOrSizes = groupToUpdate.sizes;
                                    changedGroupOptionsOrSizes = changedGroup.sizes;
                                }
                                // if groupToUpdate has options or sizes that are not in changedGroup, remove them
                                groupToUpdateOptionsOrSizes.forEach((optionOrSizeToUpdate) => {
                                    if (!changedGroupOptionsOrSizes.find((changedOptionOrSize) => groupToReplace ? optionKey(changedOptionOrSize) === optionKey(optionOrSizeToUpdate) : changedOptionOrSize.editId === optionOrSizeToUpdate.editId)) {
                                        optionsOrSizesToRemove.push(optionOrSizeToUpdate);
                                    }
                                });
                                optionsOrSizesToRemove.forEach((optionOrSizeToRemove) => {
                                    let optionOrSizeToRemoveIndex: number;
                                    if ('options' in groupToUpdate) {
                                        optionOrSizeToRemoveIndex = groupToUpdate.options.findIndex((option) => optionKey(option) === optionKey(optionOrSizeToRemove));
                                        groupToUpdate.options.splice(optionOrSizeToRemoveIndex, 1);
                                    } else if ('sizes' in groupToUpdate) {
                                        optionOrSizeToRemoveIndex = groupToUpdate.sizes.findIndex((size) => optionKey(size) === optionKey(optionOrSizeToRemove));
                                        groupToUpdate.sizes.splice(optionOrSizeToRemoveIndex, 1);
                                    }
                                });
                                changedGroupOptionsOrSizes.forEach((changedOptionOrSize) => {
                                    let groupToUpateOptionOrSize: MenuItemOption | MenuItemSize;
                                    if ('options' in groupToUpdate) {
                                        groupToUpateOptionOrSize = groupToUpdate.options.find((option) => {
                                            return groupToReplace ? optionKey(option) === optionKey(changedOptionOrSize) : option.editId === changedOptionOrSize.editId;
                                        });
                                    } else if ('sizes' in groupToUpdate) {
                                        groupToUpateOptionOrSize = groupToUpdate.sizes.find((size) => {
                                            return groupToReplace ? optionKey(size) === optionKey(changedOptionOrSize) : size.editId === changedOptionOrSize.editId;
                                        });
                                    }
                                    if (groupToUpateOptionOrSize) {
                                        const excludeProperties = ['editId', 'referenceId', 'id', 'parentId', 'options', 'optionGroups'];
                                        Object.keys(groupToUpateOptionOrSize).forEach((key) => {
                                            if (!excludeProperties.includes(key)) {
                                                if (key in changedOptionOrSize) {
                                                    groupToUpateOptionOrSize[key] = changedOptionOrSize[key];
                                                } else {
                                                    delete groupToUpateOptionOrSize[key];
                                                }
                                            }
                                            //if changedOptionOrSize has a property that groupToUpdateOptionOrSize does not, add it
                                            Object.keys(changedOptionOrSize).forEach((key) => {
                                                if (!(key in groupToUpateOptionOrSize)) {
                                                    groupToUpateOptionOrSize[key] = changedOptionOrSize[key];
                                                }
                                            });
                                        });
                                    } else {
                                        if ('options' in groupToUpdate) {
                                            groupToUpdate.options.push(changedOptionOrSize);
                                        } else if ('sizes' in groupToUpdate) {
                                            groupToUpdate.sizes.push(changedOptionOrSize);
                                        }
                                    }
                                });
                                // update groups
                                groupToUpdate.editId = changedGroup.editId;
                                if ('options' in groupToUpdate) {
                                    sortOptionsOrSizes(groupToUpdate.options);
                                } else if ('sizes' in groupToUpdate) {
                                    sortOptionsOrSizes(groupToUpdate.sizes);
                                }
                            }
                        }
                    }
                }
            }
            setReformattedMenuItems(editedMenuItemsCopy);
            interactionModelContainer.setEditedMenuItems(editedMenuItemsCopy);
            setLatestChangedOptionGroup(null);
            setLatestChangedSizeGroup(null);
            setLatestOptionGroupToReplace(null);
            setLatestSizeGroupToReplace(null);
        }
    };

    const optionKey = (option: MenuItemOption): string => {
        return `${option?.name ?? "unknown-name"}-${option.modifier ?? "unknown-modifier"}`;
    };


    const submitChanges = async () => {
        // submit interaction model form
        props.submitForm();
        // submit menu item updates
        if (reformattedMenuItems.length > 0) {
            let reload = false;
            for (const menuItem of reformattedMenuItems) {
                if (selectedGroup) {
                    setSelectedGroup(null);
                }
                if (selectedMenuItem) {
                    setSelectedMenuItem(null);
                }
                if (menuItem?.isDeleted) {
                    reload = true;
                    await applicationNlpMenuContainer.deleteVoicifyMenuItem(props.applicationId, menuItem.menuItem.id);
                }
                else if (menuItem?.isAdded) {
                    reload = true;
                    await applicationNlpMenuContainer.addVoicifyMenuItem(props.applicationId, menuItem.menuItem);
                }
                else if (menuItem?.isModified) {
                    reload = true;
                    await applicationNlpMenuContainer.updateVoicifyMenuItem(props.applicationId, menuItem.menuItem);
                }
            }
            if (reload) {
                setReformattedMenuItems([]);
                await applicationNlpMenuContainer.loadAllMenuItems(props.applicationId);
            }
        }
    }

    const closeSaveChangesModal = () => {
        setShowSaveChangesModal(false);
    };

    const handleSaveGenerativeAIAssistantPrompt = async (generativeAIAssistantPrompt: string) => {
        await applicationNlpGenAIContainer.saveGenerativeAIAssistantPrompt(props.applicationId, generativeAIAssistantPrompt);
        props.appContainer.setState((prevState) => ({
            currentApp: {
                ...prevState.currentApp,
                generativeAIAssistantPrompt: generativeAIAssistantPrompt,
            },
        }));
    }


    return <>
        {uiStateContainer.nluTab === "Intents" &&
            <CustomScrollbars autoHide autoHeight autoHeightMax={windowSize.windowSize.innerHeight - 210}>
                <ApplicationIntentsEditor
                    {...props}
                    applicationId={props.applicationId}
                    key={props.languages.map(l => l.id).join("|")}
                    languages={props.languages}
                    interactionModel={props.values.interactionModel}
                    isLoading={(interactionModelContainer.isLoading || applicationNlpEntityContainer.isLoading)}
                />
            </CustomScrollbars>
        }

        {uiStateContainer.nluTab === "Entities" &&
            <ApplicationNlpEntitiesEditor
                {...props}
                nlpEntities={props.values.nlpEntities}
                applicationId={props.applicationId}
                key={props.languages.map(l => l.id).join("|")}
                languages={props.languages}
                allAppLanguages={props.allAppLanguages}
                isLoading={(interactionModelContainer.isLoading || applicationNlpEntityContainer.isLoading)}
            />
        }

        {uiStateContainer.menuEditorView === "Menu Items" && uiStateContainer.nluTab === "Menu" &&
            <VoicifyMenuItemsEditor
                {...props}
                updatedMenuItems={reformattedMenuItems}
                setUpdatedMenuItems={setReformattedMenuItems}
                applicationId={props.applicationId}
                isLoading={applicationNlpMenuContainer.isLoading}
                selectedMenuItem={selectedMenuItem}
                setSelectedMenuItem={setSelectedMenuItem}
                menuItems={applicationNlpMenuContainer.menuItems}
                appName={appName}
            />
        }

        {uiStateContainer.nluTab === "Gen AI" &&
            <GenAI
                {...props}
                handleSave={handleSaveGenerativeAIAssistantPrompt}
                isLoading={applicationNlpGenAIContainer.isLoading}
                currentPrompt={systemPrompt}
            />
        }

        {uiStateContainer.menuEditorView === "Option Groups" && uiStateContainer.nluTab === "Menu" &&
            <VoicifyGroupsEditor
                {...props}
                performGroupChange={performGroupChange}
                setSelectedGroup={setSelectedGroup}
                groupsList={interactionModelContainer.optionGroupsList}
                setGroupsList={interactionModelContainer.setOptionGroupsList}
                setLatestChangedGroup={setLatestChangedOptionGroup}
                latestChangedGroup={latestChangedOptionGroup}
                latestGroupToReplace={latestOptionGroupToReplace}
                setLatestGroupToReplace={setLatestOptionGroupToReplace}
                matches={interactionModelContainer.optionGroupMatches}
                selectedGroup={selectedGroup}
                areGroups={areOptionGroups}
                groupType="optionGroup"
                // menu items for editing purposes
                editableMenuItems={interactionModelContainer.editedMenuItems}
                setEditableMenuItems={interactionModelContainer.setEditedMenuItems}
                // formatted menu items unedited
                originalMenuItems={reformattedMenuItems}
                setOriginalMenuItems={setReformattedMenuItems}
                menuItems={applicationNlpMenuContainer.menuItems}
                applicationId={props.applicationId}
                isLoading={applicationNlpMenuContainer.isLoading}
            />
        }

        {uiStateContainer.menuEditorView === "Size Groups" && uiStateContainer.nluTab === "Menu" &&
            <VoicifyGroupsEditor
                {...props}
                performGroupChange={performGroupChange}
                setSelectedGroup={setSelectedGroup}
                groupsList={interactionModelContainer.sizeGroupsList}
                setGroupsList={interactionModelContainer.setSizeGroupsList}
                setLatestChangedGroup={setLatestChangedSizeGroup}
                latestChangedGroup={latestChangedSizeGroup}
                latestGroupToReplace={latestSizeGroupToReplace}
                setLatestGroupToReplace={setLatestSizeGroupToReplace}
                matches={interactionModelContainer.sizeGroupMatches}
                selectedGroup={selectedGroup}
                areGroups={areSizeGroups}
                groupType="sizeGroup"
                // menu items for editing purposes
                editableMenuItems={interactionModelContainer.editedMenuItems}
                setEditableMenuItems={interactionModelContainer.setEditedMenuItems}
                // formatted menu items unedited
                originalMenuItems={reformattedMenuItems}
                setOriginalMenuItems={setReformattedMenuItems}
                menuItems={applicationNlpMenuContainer.menuItems}
                applicationId={props.applicationId}
                isLoading={applicationNlpMenuContainer.isLoading}
            />
        }

        <PageError errors={[
            ...interactionModelContainer?.errors ?? [],
            ...applicationNlpEntityContainer?.errors ?? [],
            ...applicationNlpGenAIContainer?.errors ?? [],
        ]}
            onClear={() => {
                interactionModelContainer.clearErrors();
                applicationNlpEntityContainer.clearErrors();
            }} />

        <NlpSaveChangesModal
            showSaveChangesModal={showSaveChangesModal}
            closeModal={closeSaveChangesModal}
            values={props.values}
            submitChanges={submitChanges}
            languages={props.languages}
            isLoading={(interactionModelContainer.isLoading || applicationNlpEntityContainer.isLoading)}
            errors={props.errors}
            updatedMenuItems={reformattedMenuItems}
        />
    </>
};

export default InteractionModelEditorWrapper;
