import React from 'react';
import styled from '@emotion/styled';
import TemplateConfigurationModel from '../../../models/templating/api/TemplateConfigurationModel';
import { color_colors_growth, color_shades_lighter, color_text_default, color_text_light } from '../../../constants/colors';
import { style_border_default } from '../../../constants/stylesValues';
import { Link } from 'react-router-dom';
import AppliedApplicationTemplateModel from '../../../models/templating/api/AppliedApplicationTemplateModel';
import { css } from '@emotion/css';

const defaultImage = require('../../../content/images/icon.svg');
const templateIcon = require('../../../content/images/templates-icons/template-icon-light.svg');
const integrationIcon = require('../../../content/images/templates-icons/integration-icon-light.svg');
const readyIcon = require('../../../content/images/templates-icons/generate-content.svg');
const notEnabledIcon = require('../../../content/images/templates-icons/not-enabled.svg');
const versionIcon = require('../../../content/images/templates-icons/version.svg');
const timeIcon = require('../../../content/images/ssml/time-gray.svg');

interface ModuleCardProps {
    templateConfiguration: TemplateConfigurationModel
    appliedTemplate?: AppliedApplicationTemplateModel // null if not in use
    applicationId: string
}

const ModuleCard: React.FC<ModuleCardProps> = ({
    templateConfiguration,
    appliedTemplate,
    applicationId
}) => {

    let isOutdated = true;
    if (templateConfiguration?.currentVersion?.id !== undefined && templateConfiguration?.currentVersion?.id === appliedTemplate?.appliedVersion?.id) {
        isOutdated = false;
    }
    return (
        <Link to={applicationId ? (appliedTemplate?.id ? `/v/apps/${applicationId}/modules/applied/${appliedTemplate.id}` : `/v/apps/${applicationId}/modules/${templateConfiguration?.id}/details`) : "#"} className={`${moduleLink} modules-card ac-framework-list-item ac-module-list-item`}>
            <CardBody>
                <CardHeader />
                <CardImageWrapper>
                    <img src={templateConfiguration?.imageUrl ?? defaultImage} />
                </CardImageWrapper>
                <InnerCardBody>
                    <p className="version-label">{templateConfiguration?.currentVersion?.versionName ? `version: ${templateConfiguration.currentVersion.versionName}` : ' '}</p>
                    <p className="template-title">{appliedTemplate?.name?.length > 0 ? appliedTemplate.name : templateConfiguration?.name}</p>
                    <p className="template-byline">
                        <img src={templateConfiguration?.templateType == "Integration" ? integrationIcon : templateIcon} />
                        <span>{`${templateConfiguration?.templateType == "Integration" ? "Integration" : "Template"} - by ${templateConfiguration?.ownerName}`}</span>
                    </p>
                    <p className="template-details">
                        <img src={timeIcon} />
                        <span>Last Sync Date: {appliedTemplate?.lastSyncedDate ? new Date(appliedTemplate?.lastSyncedDate).toDateString() : new Date().toDateString()}</span>
                    </p>
                    <p className="template-details">
                        <img src={versionIcon} />
                        <span>Applied Version: {appliedTemplate?.appliedVersion?.versionName ?? "NA"}</span>
                    </p>

                    <CardDescription>
                        <p className="template-description">{templateConfiguration.shortDescription}</p>
                    </CardDescription>
                </InnerCardBody>
                <CardFooter>
                    <p className="template-status">
                        <span>{appliedTemplate ? "Enabled" : "Not Enabled"}</span>
                        <img src={appliedTemplate ? readyIcon : notEnabledIcon} />
                    </p>
                </CardFooter>
            </CardBody>
        </Link>
    )
}

const moduleLink = css`
    display: inline-block;
`
const CardBody = styled.div(`
    border: ${style_border_default};
    box-sizing: border-box;
    border-radius: 8px;
    background: white;
    width: 340px;
    height: 376px;
    position: relative;
    margin-left: 32px;
    margin-top: 32px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    color: ${color_text_default};
`);

const CardHeader = styled.div(`
    background: ${color_shades_lighter};
    height: 102px;
    border-bottom: ${style_border_default};
`);

const CardDescription = styled.div(`
    height: 72px;    
    overflow: hidden;
`);

const CardImageWrapper = styled.div(`
    position: absolute;
    width: 96px;
    height: 96px;
    margin-left: 24px;
    margin-top: 24px;
    background: white;
    border: ${style_border_default};
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    img {
        max-height: 100%;
        max-width: 100%;
    }
`);

const InnerCardBody = styled.div(`
    display: flex;
    flex-direction: column;
    margin: 24px;
    color: ${color_text_default};
    flex: 1;
    .version-label {
        margin-right: 0;
        margin-left: auto;
        text-align: right;        
        font-size: 14px;
        line-height: 20px;
        height: 20px;
    }
    .template-title {
       margin-top: 16px;
        margin-bottom: 5px;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 32px;
    }
    .template-byline {
        display: flex;
        align-items: center;
        color: ${color_text_light};
        font-size: 14px;
        line-height: 20px;
        img {
            margin-right: 8px;
        }
    }
    .template-details {
        display: flex;
        align-items: center;
        color: ${color_text_light};
        font-size: 14px;
        line-height: 20px;
        margin-top: 8px;
        img {
            margin-right: 8px;
        }
    }
    .template-description {
        margin-top: 8px;
    }
`);

const CardFooter = styled.div(`
    height: 72px;
    border-top: ${style_border_default};
    margin: 0 24px;
    display: flex;
    align-items: center;
    .template-price {
        .price-label {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            color: ${color_text_light};
            display: block;
        }
        .price-value {
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 24px;
            display: block;
        }
    }
    .template-status {
        margin-right: 0;
        margin-left: auto;
        font-size: 14px;
        line-height: 20px;
        font-style: italic;
        color: ${color_text_light};
        display: flex;
        align-items: center;
        &.enabled {
            color: ${color_colors_growth};
        }
        img {
            width: 24px;
            margin-left: 8px;
        }
    }
`);

export default ModuleCard
