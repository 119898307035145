import React, { FocusEvent, Fragment } from 'react';
import { css } from '@emotion/css';
import MediaSelectionCard from '../../../components/forms/MediaSelectionCard';
import SsmlField from '../../../components/forms/SsmlField/SsmlField';
import Button from '../../../components/general/Button';
import ContentCard from '../../../components/structure/ContentCard';
import ContentCollapsingSection from '../../../components/structure/ContentCollapsingSection';
import FormFieldsContainer from '../../../components/structure/FormFieldsContainer';
import MediaResponseManager from '../../mediaResponseManager';
import FeatureContainer from '../../../state/containers/FeatureContainer';
import RepromptContainer from '../../../state/containers/RepromptContainer';
import ManageFollowUpForm from '../../followUpForm/components/ManageFollowUpForm';
import ManageRepromptForm from '../../followUpForm/components/ManageRepromptForm';
import FollowUpContainer from '../../../state/containers/FollowUpContainer';
import ConditionsContainer from '../../../state/containers/ConditionsContainer';
import ConditionInstanceModel from '../../../models/features/api/Conditions/ConditionInstanceModel';
import { MediaManagerData } from '../../mediaResponseManager/components/MediaManagerForm';
import FollowUpModel from '../../../models/features/api/FollowUp/FollowUpModel';
import ChildContentContainerModel from '../../../models/features/api/Relationships/ChildContentContainerModel';
import RepromptModel from '../../../models/features/api/Reprompt/RepromptModel';
import LanguageModel from '../../../models/features/api/LanguageModel';
import ConditionsGroup from './ConditionsGroup';
import ConditionManager from './ConditionManager';
import _ from 'lodash';
import AddResponseSection from './AddResponseSection';
import RepromptManager from '../../repromptManager';
import FollowUpManager from '../../followUpManager';
import ResponseGroup from '../../../models/features/ResponseGroup';
import LargeOverflowMenu from '../../../components/general/LargeOverflowMenu';
import MediaResponseStateContainer from '../../../state/containers/MediaResponseContainer';
import ApplicationFlagsContainer from '../../../state/containers/ApplicationFlagsContainer';
import ResponseFlagRuleModel from '../../../models/features/api/Flags/ResponseFlagRuleModel';
import { color_colors_decline, color_colors_ocean, color_gradients_silver_shift, color_shades_darker, color_shades_darkest, color_text_default, color_text_link, color_variants_ocean_light_opaque, silver_four } from '../../../constants/colors';
import { style_border_default } from '../../../constants/stylesValues';
import styled from '@emotion/styled';
import FlagRulesGroup from './FlagRulesGroup';
import FlagRuleCreator from './FlagRuleCreator';
import HorizontalSeparator from '../../../components/structure/HorizontalSeparator';
import { reorder } from '../../../models/extensions';
import { Draggable, DropResult, ResponderProvided } from 'react-beautiful-dnd';
import ResponseEffectsList from './ResponseEffectsList';
import EffectModel from '../../../models/features/api/Effects/EffectModel';
import EffectManager from './EffectManager';
import { CountButton } from '../../contentPage/components/CountButton';
import TextFieldSubtitle from '../../../components/forms/TextFieldSubtitle';
import TextAreaField from '../../../components/forms/TextAreaField';
import Switch from '../../../components/forms/Switch';
import HorizontalOptionsSelector from '../../../components/forms/HorizontalOptionsSelector';
import IconTooltip from '../../../components/general/IconTooltip';

const outputIcon = require('../../../content/images/device.svg');
const shuffleIcon = require('../../../content/images/content-item-icons/button-shuffle.svg');
const decisionIcon = require('../../../content/images/content-item-icons/decision.svg');
const responseGroupIcon = require('../../../content/images/content-tree-icons/content-level/response-stack.svg');
const userFlagsIcon = require('../../../content/images/content-item-icons/user-flags.svg');
const userFlagsSmallIcon = require('../../../content/images/content-item-icons/user-flags-small.svg');
const userFlagsSmallBlueIcon = require('../../../content/images/content-item-icons/user-flags-small-blue.svg');
const requiredStarIcon = require('../../../content/images/webhooks/parameter-required-star.svg')
const upIcon = require('../../../content/images/menu-item-icons/navigation-up.svg');
const downIcon = require('../../../content/images/menu-item-icons/navigation-down.svg');
const deleteIcon = require('../../../content/images/content-item-icons/delete-blue.svg');
const effectIcon = require('../../../content/images/content-item-icons/effect.svg');
const effectBlueIcon = require('../../../content/images/content-item-icons/effect-blue.svg');

const temperatureOptions = [
    { name: '.1', value: .1 },
    { name: '.2', value: .2 },
    { name: '.3', value: .3 },
    { name: '.4', value: .4 },
    { name: '.5', value: .5 },
    { name: '.6', value: .6 },
    { name: '.7', value: .7 },
    { name: '.8', value: .8 },
    { name: '.9', value: .9 },
    { name: '1', value: 1 },
];

interface ResponsesProps {
    featureContainer: FeatureContainer
    followUpContainer: FollowUpContainer
    repromptContainer: RepromptContainer
    conditionsContainer: ConditionsContainer
    mediaResponseContainer: MediaResponseStateContainer
    appFlagsContainer: ApplicationFlagsContainer
    applicationId: string
    applicationFeatureId: string
    hideFollowUp?: boolean
    responseGroups: ResponseGroup[]
    getConditionsKey: (conditionInstances: ConditionInstanceModel[]) => string
    onChange: (responseGroups: ResponseGroup[]) => void
    isDisabled?: boolean
    onBlur?: (e: FocusEvent<any>) => void
    emptyFollowUpOptionText?: string
    emptyFollowUpOptionIcon?: string
    languages: LanguageModel[]
}
interface ResponsesState {
    isMediaToggled: boolean
    isFollowUpToggled: boolean
    isRepromptToggled: boolean
    isFlagsToggled: boolean
    isEffectsToggled: boolean
    editingEffect?: EffectModel
    isConditionToggled: boolean
    isCloningDefault: boolean
    hasFollowUpChanges?: boolean
    expandedFlagGroupKeys: string[]
    expandedEffectGroupKeys: string[]
    editingResponseGroup?: ResponseGroup
    editingCondition?: ConditionInstanceModel
    editingParentCondition?: ConditionInstanceModel
}
class Responses extends React.Component<ResponsesProps, ResponsesState> {
    constructor(props) {
        super(props);
        this.state = {
            isMediaToggled: false,
            isFollowUpToggled: false,
            isRepromptToggled: false,
            hasFollowUpChanges: false,
            isFlagsToggled: false,
            isEffectsToggled: false,
            editingEffect: null,
            isConditionToggled: false,
            isCloningDefault: false,
            editingResponseGroup: null,
            editingCondition: null,
            editingParentCondition: null,
            expandedFlagGroupKeys: [] as string[],
            expandedEffectGroupKeys: [] as string[]
        }
    }
    componentDidMount() {
        this.props.conditionsContainer.getConditions();
        this.props.appFlagsContainer.loadApplicationFlags(this.props.applicationId);
    }
    handleSsmlUpdate(ssml: string, responseGroup: ResponseGroup, index: number) {
        const groups = this.props.responseGroups;
        const editedGroup = groups.find(g => g.key == responseGroup.key);
        if (!editedGroup) return;

        editedGroup.responses[index].content = ssml;
        this.props.onChange(groups);
    }
    handleSystemPromptUpdate(s: string, responseGroup: ResponseGroup, index: number) {
        const groups = this.props.responseGroups;
        const editedGroup = groups.find(g => g.key == responseGroup.key);
        if (!editedGroup) return;

        editedGroup.responses[index].systemPrompt = s;
        this.props.onChange(groups);
    }
    handleContextualSystemPrompt(s: string, responseGroup: ResponseGroup, index: number) {
        const groups = this.props.responseGroups;
        const editedGroup = groups.find(g => g.key == responseGroup.key);
        if (!editedGroup) return;

        editedGroup.responses[index].contextualSystemPrompt = s;
        this.props.onChange(groups);
    }
    handleTemperature(t: number, responseGroup: ResponseGroup, index: number) {
        const groups = this.props.responseGroups;
        const editedGroup = groups.find(g => g.key == responseGroup.key);
        if (!editedGroup) return;

        editedGroup.responses[index].temperature = t;
        this.props.onChange(groups);
    }
    handleToggleGenerativeResponse(responseGroup: ResponseGroup) {
        const groups = this.props.responseGroups;
        const editedGroup = groups.find(g => g.key == responseGroup.key);
        if (!editedGroup) return;

        const newBool = !editedGroup.responses[0].useEnhancedResponse;
        editedGroup.responses.forEach(r => r.useEnhancedResponse = newBool);
        this.props.onChange(groups);
    }
    handleAddSsmlVariation(responseGroup: ResponseGroup) {
        const groups = this.props.responseGroups;
        const editedGroup = groups.find(g => g.key == responseGroup.key);
        if (!editedGroup) return;

        if (!editedGroup?.responses?.length) {
            editedGroup.responses.push({
                conditions: responseGroup.conditions,
                content: ''
            })
        } else {
            var firstResponse = editedGroup.responses[0];
            editedGroup.responses.push({
                ...firstResponse,
                conditions: responseGroup.conditions,
                content: ''
            })
        }
        this.props.onChange(groups);
    }
    handleRemoveVariation(responseGroup: ResponseGroup, index: number) {
        const groups = this.props.responseGroups;
        const editedGroup = groups.find(g => g.key == responseGroup.key);
        if (!editedGroup) return;

        editedGroup.responses.splice(index, 1);
        this.props.onChange(groups);
    }
    handleCopyVariation(responseGroup: ResponseGroup, index: number) {
        const groups = this.props.responseGroups;
        const editedGroup = groups.find(g => g.key == responseGroup.key);
        if (!editedGroup) return;
        const newResponse = { ...responseGroup.responses[index] }
        newResponse.id = null;

        editedGroup.responses.splice(index, 0, newResponse);
        this.props.onChange(groups);
    }
    handleUploadMedia(responseGroup: ResponseGroup) {
        this.setState({
            ...this.state,
            isMediaToggled: true,
            editingResponseGroup: responseGroup
        })
    }
    handleUploadMediaClose() {
        this.setState({
            ...this.state,
            isMediaToggled: false,
            editingResponseGroup: null
        })
    }
    handleMediaSelected(data: MediaManagerData, responseGroup?: ResponseGroup) {
        const groups = this.props.responseGroups;
        const editedResponseGroup = responseGroup ?? this.state.editingResponseGroup;
        const editedGroup = groups.find(g => g.key == editedResponseGroup.key);
        if (!editedGroup) return;
        editedGroup.responses.forEach(r => {
            r.audio = data.audioItem;
            r.backgroundImage = data.backgroundImageItem;
            r.displayTextOverride = data.displayTextOverride;
            r.displayTitleOverride = data.displayTitleOverride;
            r.largeImage = data.imageItem;
            r.mediaResponseContainerId = data.fullContainer?.id;
            r.mediaResponseContainer = data.fullContainer;
            r.video = data.videoItem;
        })
        this.props.onChange(groups);

        this.setState({
            ...this.state,
            isMediaToggled: false
        })
    }
    handleFollowUpChange(followUp: FollowUpModel, childItemContainer: ChildContentContainerModel, responseGroup?: ResponseGroup) {
        const groups = this.props.responseGroups;
        const editedResponseGroup = responseGroup ?? this.state.editingResponseGroup;
        const editedGroup = groups.find(g => g.key == editedResponseGroup.key);
        if (!editedGroup) return;
        if (followUp) {
            followUp.childContentContainer = childItemContainer;
        }
        editedGroup.firstResponse.followUp = followUp;
        editedGroup.firstResponse.followUpId = followUp?.id;
        editedGroup.responses.forEach(r => {
            r.followUp = followUp;
            r.followUpId = followUp?.id;
        })
        this.props.onChange(groups);
        this.setState({
            ...this.state,
            hasFollowUpChanges: true,
            isFollowUpToggled: false
        })
    }
    handleRepromptChange(reprompt: RepromptModel, responseGroup?: ResponseGroup) {
        const groups = this.props.responseGroups;
        const editedResponseGroup = responseGroup ?? this.state.editingResponseGroup;
        const editedGroup = groups.find(g => g.key == editedResponseGroup.key);
        if (!editedGroup) return;
        editedGroup.firstResponse.reprompt = reprompt;
        editedGroup.responses.forEach(r => {
            r.reprompt = reprompt;
        })
        this.props.onChange(groups);
        this.setState({
            ...this.state,
            hasFollowUpChanges: true,
            isRepromptToggled: false
        })
    }
    handleRepromptClose() {
        this.setState({
            ...this.state as object,
            isRepromptToggled: false,
            editingResponseGroup: null
        })
    }
    handleFollowUpClose() {
        this.setState({
            ...this.state as object,
            isFollowUpToggled: false,
            editingResponseGroup: null
        })
    }
    handleToggleGroupFlags(responseGroup: ResponseGroup) {
        const flagGroups = this.state.expandedFlagGroupKeys;
        const effectGroups = this.state.expandedEffectGroupKeys;
        if (flagGroups.some(g => g == responseGroup.key)) {
            _.remove(flagGroups, g => g == responseGroup.key);
        } else {
            flagGroups.push(responseGroup.key);
        }
        if (effectGroups.some(g => g == responseGroup.key)) {
            _.remove(effectGroups, g => g == responseGroup.key);
        }

        this.setState({
            ...this.state,
            expandedFlagGroupKeys: flagGroups,
            expandedEffectGroupKeys: effectGroups,
        })
    }
    handleToggleGroupEffects(responseGroup: ResponseGroup) {
        const effectGroups = this.state.expandedEffectGroupKeys;
        const flagGroups = this.state.expandedFlagGroupKeys;
        if (effectGroups.some(g => g == responseGroup.key)) {
            _.remove(effectGroups, g => g == responseGroup.key);
        } else {
            effectGroups.push(responseGroup.key);
        }
        if (flagGroups.some(g => g == responseGroup.key)) {
            _.remove(flagGroups, g => g == responseGroup.key);
        }

        this.setState({
            ...this.state,
            expandedEffectGroupKeys: effectGroups,
            expandedFlagGroupKeys: flagGroups
        })
    }
    handleEditFlags(responseGroup: ResponseGroup) {
        this.setState({
            ...this.state,
            isFlagsToggled: true,
            editingResponseGroup: responseGroup
        })
    }

    handleAddEffect(responseGroup: ResponseGroup) {
        this.setState({
            ...this.state,
            isEffectsToggled: true,
            editingEffect: null,
            editingResponseGroup: responseGroup
        })
    }

    handleEditEffect(responseGroup: ResponseGroup, effect: EffectModel) {
        this.setState({
            ...this.state,
            isEffectsToggled: true,
            editingEffect: effect,
            editingResponseGroup: responseGroup
        })
    }

    handleEditReprompt(responseGroup: ResponseGroup) {
        this.setState({
            ...this.state as object,
            isRepromptToggled: true,
            editingResponseGroup: responseGroup
        })
    }
    handleShowRepromptModal(responseGroup: ResponseGroup) {
        this.setState({
            ...this.state,
            isRepromptToggled: true,
            editingResponseGroup: responseGroup
        })
    }
    handleShowFollowUpModal(responseGroup: ResponseGroup) {
        this.setState({
            ...this.state,
            isFollowUpToggled: true,
            editingResponseGroup: responseGroup
        })
    }
    handleCancelCondition() {
        this.setState({
            ...this.state,
            isConditionToggled: false,
            editingCondition: null,
            editingParentCondition: null,
            editingResponseGroup: null
        })
    }
    handleCancelFlags() {
        this.setState({
            ...this.state,
            isFlagsToggled: false,
            editingResponseGroup: null
        })
    }
    handleCancelEffects() {
        this.setState({
            ...this.state,
            isEffectsToggled: false,
            editingResponseGroup: null
        })
    }
    handleAddFlagToGroup(flagRule: ResponseFlagRuleModel) {
        const groups = this.props.responseGroups;
        const editedResponseGroup = this.state.editingResponseGroup;
        const editedGroup = groups.find(g => g.key == editedResponseGroup.key);
        if (!editedGroup) return;
        editedGroup.responses.forEach(r => {
            r.flagRules = [...(r.flagRules ?? []), flagRule];;
        })
        this.props.onChange(groups);
        this.setState({
            ...this.state,
            isFlagsToggled: false,
        })
    }
    handleRemoveFlagFromGroup(flagRule: ResponseFlagRuleModel, responseGroup: ResponseGroup) {
        const groups = this.props.responseGroups;
        if (!responseGroup) return;
        responseGroup.responses.forEach(r => {
            _.remove(r.flagRules, f => f == flagRule);
        })
        this.props.onChange(groups);
        this.setState({
            ...this.state,
            isFlagsToggled: false,
        })
    }
    handleEffectManagerFinish(effect: EffectModel) {
        const groups = this.props.responseGroups;
        const editedResponseGroup = this.state.editingResponseGroup;
        const editedGroup = groups.find(g => g.key == editedResponseGroup.key);
        if (!editedGroup) return;
        const existingEffects = editedGroup.responses[0].effects;
        if (this.state.editingEffect) {
            const replaceIndex = existingEffects.findIndex(e => e === this.state.editingEffect);
            existingEffects[replaceIndex] = effect;
            editedGroup.responses.forEach(r => {
                r.effects = existingEffects;
            })
        } else {
            const priority = existingEffects?.length ?? 0;
            effect.priority = priority;
            editedGroup.responses.forEach(r => {
                r.effects = [...(r.effects ?? []), effect];
            })
        }
        this.props.onChange(groups);
        this.setState({
            ...this.state,
            isEffectsToggled: false,
        })
    }
    handleUpdateEffects(newEffects: EffectModel[], responseGroupKey: string) {
        const groups = this.props.responseGroups;
        const editedResponseGroup = groups.find(g => g.key == responseGroupKey);
        if (!editedResponseGroup) return;
        editedResponseGroup.responses.forEach(r => {
            r.effects = newEffects;
        })
        this.props.onChange(groups);
    }
    handleRemoveEffectFromGroup(effect: EffectModel, responseGroup: ResponseGroup) {
        const groups = this.props.responseGroups;
        if (!responseGroup) return;

        //remove the effect from the first response
        _.remove(responseGroup.responses[0].effects, e => {
            return JSON.stringify({ ...e, id: null }) === JSON.stringify({ ...effect, id: null })
        });

        //make sure the alternatives response have the same effect array
        for (let i = 0; i < responseGroup.responses.length; i++) {
            responseGroup.responses[i].effects = responseGroup.responses[0].effects;
        }

        this.props.onChange(groups);
        this.setState({
            ...this.state,
            isEffectsToggled: false,
        })
    }
    findConditionInstance(conditionInstanceId: string, conditionInstances: ConditionInstanceModel[]) {
        let conditionInstance: ConditionInstanceModel = null
        const checkInstance = (ci: ConditionInstanceModel) => {
            if (ci.id == conditionInstanceId) return ci;
            if (ci.andCondition) {
                return checkInstance(ci.andCondition);
            }
            return null;
        }
        conditionInstances?.forEach(ci => {
            const matchedInstance = checkInstance(ci);
            if (matchedInstance != null) {
                conditionInstance = matchedInstance;
            }
        })
        return conditionInstance;
    }
    duplicateGroup(responseGroup: ResponseGroup, conditionInstance: ConditionInstanceModel, priority?: number): ResponseGroup {
        const responses = responseGroup.responses?.map(r => ({
            ...r,
            id: null,
            responseId: null,
            flagRules: r.flagRules?.map(f => ({ ...f })) ?? [],
            followUp: r.followUp ? {
                ...r.followUp,
                id: null
            } : null,
            reprompt: r.reprompt ? {
                ...r.reprompt,
                id: null
            } : null,
            mediaResponseContainer: r.mediaResponseContainer ? {
                ...r.mediaResponseContainer,
                id: null,
                mediaResponseContainerId: null,
                responses: r.mediaResponseContainer?.responses?.map(mr => ({
                    ...mr,
                    id: null
                }))
            } : null,
            groupPriority: priority
        }));

        const newGroup: ResponseGroup = {
            key: this.props.getConditionsKey([conditionInstance]),
            conditions: [conditionInstance],
            responses,
            firstResponse: responses?.[0],
            priority
        };

        return newGroup;
    };

    handleConditionChange(conditionInstance: ConditionInstanceModel) {
        const { editingCondition, editingParentCondition, editingResponseGroup, isCloningDefault } = this.state
        const groups = this.props.responseGroups;
        const priority: number = groups.length;
        // if there is no group, create a new group with an empty response and the condition
        if (!editingResponseGroup) {
            const defaultGroup = groups.find(g => !g.key);
            const newGroup: ResponseGroup = isCloningDefault
                ? this.duplicateGroup(defaultGroup, conditionInstance, priority)
                : {
                    key: this.props.getConditionsKey([conditionInstance]),
                    conditions: [conditionInstance],
                    responses: [{
                        content: ''
                    }],
                    firstResponse: {
                        content: ''
                    },
                    priority
                };

            groups.push(newGroup);
            // push to link to scroll to the new group
            location.href = `#g${groups.length - 1}deviceResponse`
        } else {
            const editedGroup = groups.find(g => g.key == editingResponseGroup.key);

            // if there is no parent and no edit, add to the list
            if (!editingCondition && !editingParentCondition) {
                editedGroup.conditions.push(conditionInstance);
            }
            // if there is a parent and no edit, add to the parent
            else if (!editingCondition) {
                const editedParent = this.findConditionInstance(editingParentCondition.id, editedGroup.conditions);
                editedParent.andCondition = conditionInstance;
            }
            // if there is an edit, update the instance
            else if (editingCondition) {
                const edited = this.findConditionInstance(editingCondition.id, editedGroup.conditions);
                edited.value = conditionInstance.value;
                edited.condition = conditionInstance.condition;
                edited.conditionId = conditionInstance.conditionId;
                edited.andCondition = conditionInstance.andCondition;
            }

        }
        this.props.onChange(groups);
        this.setState({
            ...this.state,
            isConditionToggled: false,
            editingCondition: null,
            editingParentCondition: null,
            editingResponseGroup: null
        })
    }
    handleRemoveCondition(conditionInstance: ConditionInstanceModel, responseGroup: ResponseGroup) {
        // loop through the chain until we find by id, then remove and move the andCondition up to the parent
        const groups = this.props.responseGroups;
        const editedGroup = groups.find(g => g.key == responseGroup.key);
        const checkParent = (ci: ConditionInstanceModel) => {
            if (ci.andCondition?.id == conditionInstance.id) return ci;
            if (ci.andCondition) {
                return checkParent(ci.andCondition);
            }
            return null;
        }
        editedGroup?.conditions?.forEach((ci, i) => {
            if (ci.id == conditionInstance.id) {
                // if you have an andCondition, rechain before removing it
                if (conditionInstance.andCondition) {
                    editedGroup.conditions.splice(i, 1, conditionInstance.andCondition);
                } else {
                    editedGroup.conditions.splice(i, 1);
                }
                return;
            }
            const matchedParent = checkParent(ci);
            if (matchedParent != null) {
                matchedParent.andCondition = matchedParent.andCondition?.andCondition;
            }
        });
        this.props.onChange(groups);
    }
    handleAddConditionResponse() {
        this.setState({
            ...this.state,
            isConditionToggled: true,
            isCloningDefault: false
        })
    }
    handleCloneConditionResponse() {
        this.setState({
            ...this.state,
            isConditionToggled: true,
            isCloningDefault: true
        })
    }
    handleEditCondition(conditionInstance: ConditionInstanceModel, responseGroup: ResponseGroup) {
        this.setState({
            ...this.state,
            editingResponseGroup: responseGroup,
            editingCondition: conditionInstance,
            isConditionToggled: true
        })
    }
    handleAddConditionGroup(responseGroup: ResponseGroup) {
        this.setState({
            ...this.state,
            editingResponseGroup: responseGroup,
            isConditionToggled: true
        })
    };

    handleAddAndCondition(parentConditionInstance: ConditionInstanceModel, responseGroup: ResponseGroup) {
        this.setState({
            ...this.state,
            editingResponseGroup: responseGroup,
            editingParentCondition: parentConditionInstance,
            editingCondition: null,
            isConditionToggled: true
        })
    };

    handleDeleteConditionalResponseGroup(groupIndex: number) {
        const groups = _.orderBy(this.props.responseGroups, ['priority', 'key']);
        groups.splice(groupIndex, 1);

        this.props.onChange(groups);
    };

    reorderGroups(sourceIndex: number, destIndex: number) {
        let groups: ResponseGroup[] = _.orderBy(this.props.responseGroups, ['priority', 'key']);
        // Non conditional response groups (currently only one - Default) should be above conditional response groups
        // Cannot reorder Default  group
        if (!groups[destIndex]?.key) return;

        groups = reorder(groups, sourceIndex, destIndex);
        groups.forEach((group, i) => {
            const groupPriority = i;
            group.priority = groupPriority;
            group.responses?.forEach(response => {
                response.groupPriority = groupPriority
            });
        });

        this.props.onChange(groups);
    };

    handleConditionalResponseGroupDragEnd(result: DropResult, provider: ResponderProvided) {
        // dropped outside the list
        if (!result.destination) {
            return;
        };
        const sourceIndex: number = result.source.index;
        const destIndex: number = result.destination.index;
        this.reorderGroups(sourceIndex, destIndex);
    };

    handleMoveUpConditionalResponseGroup(groupIndex: number) {
        const groups = _.orderBy(this.props.responseGroups, ['priority', 'key']);
        // Non conditional response groups (currently only one - Default) should be above conditional response groups
        // Cannot reorder Default  group
        if (!groups[groupIndex - 1]?.key) return;

        if (groupIndex > 0) {
            this.reorderGroups(groupIndex, groupIndex - 1);
        }
    };

    handleMoveDownConditionalResponseGroup(groupIndex: number) {
        const groups = _.orderBy(this.props.responseGroups, ['priority', 'key']);
        if (groupIndex < groups.length - 1) {
            this.reorderGroups(groupIndex, groupIndex + 1);
        }
    };

    getOptions(moveUpDisabled: boolean) {
        let options = [
            { label: 'Delete Response Group', icon: deleteIcon, hoverIcon: deleteIcon, isDestructive: true },
            { label: 'Move Up', icon: upIcon, hoverIcon: upIcon },
            { label: 'Move Down', icon: downIcon, hoverIcon: downIcon }
        ];
        if (moveUpDisabled) {
            options = [
                { label: 'Delete Response Group', icon: deleteIcon, hoverIcon: deleteIcon, isDestructive: true },
                { label: 'Move Down', icon: downIcon, hoverIcon: downIcon }
            ];
        }
        return options;
    }

    handleGroupOptionSelected(optionName: string, index: number, e: any) {
        e.stopPropagation();
        if (optionName === "Delete Response Group") {
            this.handleDeleteConditionalResponseGroup(index);
        }
        if (optionName === 'Move Up') {
            this.handleMoveUpConditionalResponseGroup(index);
        }
        if (optionName === 'Move Down') {
            this.handleMoveDownConditionalResponseGroup(index);
        }
    };

    renderDraggableResponseGroupContent(group: ResponseGroup, groupIndex: number, priorGroup: ResponseGroup) {
        return (
            <Draggable draggableId={group.key} index={groupIndex} key={group.key}>
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}>
                        {this.renderResponseGroupContent(group, groupIndex, priorGroup, true, snapshot.isDragging)}
                    </div>
                )}
            </Draggable>
        );
    };

    renderResponseGroupContent(group: ResponseGroup, groupIndex: number, priorGroup: ResponseGroup, isDraggable: boolean, isDragging: boolean) {
        const { onBlur, isDisabled, applicationId, featureContainer, followUpContainer, repromptContainer, conditionsContainer, hideFollowUp } = this.props;
        const isGroupFlagsActive = this.state.expandedFlagGroupKeys.some(g => g == group.key);
        const isGroupEffectsActive = this.state.expandedEffectGroupKeys.some(g => g == group.key);
        const moveUpDisabled: boolean = !priorGroup?.key;
        const displayTitle = group.key == '' ? "Default Response" : `Conditional Response ${groupIndex}`;
        let className = group.key == '' ? "ac-default-response-section" : "ac-conditional-response-section";
        if (isDraggable) className = className + " draggable"
        if (isDragging) className = className + " dragging"

        return (
            <ContentCollapsingSection
                key={group.key || 'default'}
                index={groupIndex}
                title={displayTitle}
                icon={outputIcon}
                className={className}
                id={`g${groupIndex}deviceResponse`}
                titleRightRowComponent={
                    <div className={menuWrapperStyle}>
                        <CountButton
                            isActive={isGroupEffectsActive}
                            onClick={() => this.handleToggleGroupEffects(group)}
                            activeIcon={effectBlueIcon}
                            inactiveIcon={effectIcon}
                            showCount={true}
                            text="Effects"
                            count={group.firstResponse.effects?.length}
                        />
                        <CountButton
                            isActive={isGroupFlagsActive}
                            onClick={() => this.handleToggleGroupFlags(group)}
                            activeIcon={userFlagsSmallBlueIcon}
                            inactiveIcon={userFlagsSmallIcon}
                            showCount={true}
                            text="User Flags"
                            count={group.firstResponse.flagRules?.length}
                        />
                        <div onClick={(e) => e.stopPropagation()}>
                            {group.key ? <ConditionalGenerativeSwitch
                                label="Generative"
                                checked={group.firstResponse.useEnhancedResponse}
                                onChange={() => this.handleToggleGenerativeResponse(group)} /> :
                                <GenerativeSwitch
                                    label="Generative"
                                    checked={group.firstResponse.useEnhancedResponse}
                                    onChange={() => this.handleToggleGenerativeResponse(group)} />
                            }
                        </div>
                        {group.key &&
                            <LargeOverflowMenu isActive={false}
                                onOptionSelected={(o, e) => this.handleGroupOptionSelected(o, groupIndex, e)}
                                options={this.getOptions(moveUpDisabled)} />}
                    </div>}>
                {isGroupFlagsActive &&
                    <HighlightSection>
                        <ContentCard title="User Flags" icon={userFlagsIcon} id={`g${groupIndex}flags`}>
                            <FlagRulesGroup
                                flagRules={group.firstResponse?.flagRules}
                                onAddRule={() => this.handleEditFlags(group)}
                                onRemoveRule={(flagRule) => this.handleRemoveFlagFromGroup(flagRule, group)} />
                        </ContentCard>
                    </HighlightSection>}
                {isGroupEffectsActive &&
                    <HighlightSection>
                        <ContentCard title="Effects" icon={effectIcon} id={`g${groupIndex}flags`}>
                            <ResponseEffectsList
                                effects={group.firstResponse?.effects}
                                updateEffects={(e) => this.handleUpdateEffects(e, group.key)}
                                onAddEffect={() => this.handleAddEffect(group)}
                                onClickEffect={(effect) => this.handleEditEffect(group, effect)}
                                onRemoveEffect={(effect) => this.handleRemoveEffectFromGroup(effect, group)} />
                        </ContentCard>
                    </HighlightSection>}
                {group.conditions?.length > 0 &&
                    <ContentCard title="Conditions" icon={decisionIcon} id={`g${groupIndex}conditions`}>
                        <ConditionsGroup conditionInstances={group.conditions}
                            conditions={conditionsContainer.state.conditions}
                            deviceTypes={this.props.mediaResponseContainer?.state?.deviceTargets}
                            onAddAndCondition={(parent) => this.handleAddAndCondition(parent, group)}
                            onAddConditionGroup={() => this.handleAddConditionGroup(group)}
                            onEditCondition={(condition) => this.handleEditCondition(condition, group)}
                            onRemoveCondition={(condition) => this.handleRemoveCondition(condition, group)}
                            canDelete={group.conditions.length > 1 || group.conditions[0].andCondition != null} />
                    </ContentCard>
                }
                <ContentCard
                    title="Response Variations"
                    className="ac-response-card"
                    icon={responseGroupIcon}
                    id="responseOptions"
                    rightRender={() => <><RequiredContainer><RequiredText>At least one required</RequiredText><RequiredAsterixIcon src={requiredStarIcon} /></RequiredContainer></>}>
                    <ContentCardSubText>
                        <SubTextIcon src={shuffleIcon} />
                        <div>Chosen at Random</div>
                    </ContentCardSubText>
                    <FormFieldsContainer>
                        {group.responses.map((answer, index) => (
                            <Fragment key={"a" + index}>
                                <TextFieldSubtitle
                                    titleText={`Variation #${index + 1}`}
                                    canCopy={true}
                                    canDelete={index > 0}
                                    onDelete={() => this.handleRemoveVariation(group, index)}
                                    onCopy={() => this.handleCopyVariation(group, index)} />
                                <div key={"s" + index}>
                                    {answer.useEnhancedResponse &&
                                        <TextAreaField
                                            id={`g${groupIndex}systemPrompt${index}`}
                                            disabled={isDisabled}
                                            name={`responses.${index}.systemPrompt`}
                                            value={answer?.systemPrompt ?? ""}
                                            placeholder="A system prompt for this response."
                                            label={`System Prompt`}
                                            tooltip='A system level prompt given to the generative AI model<br />
                                            that describes how it should respond.<br />
                                            This is optional and added before the conversation history.'
                                            onChange={(e) => this.handleSystemPromptUpdate(e.target.value, group, index)}
                                            onBlur={onBlur}
                                            className="ac-response-ssml" />}
                                    {answer.useEnhancedResponse &&
                                        <TextAreaField
                                            id={`g${groupIndex}contextualSystemPrompt${index}`}
                                            disabled={isDisabled}
                                            name={`responses.${index}.contextualSystemPrompt`}
                                            value={answer?.contextualSystemPrompt ?? ""}
                                            placeholder="A prompt given to the Generative AI right before this turn of the conversation."
                                            label={`Contextual System Prompt`}
                                            tooltip='A system prompt given to the generative AI model<br />
                                            right before the most recent turn of the conversation.<br />
                                            Can be more specific for the current conversation turn.'
                                            onChange={(e) => this.handleContextualSystemPrompt(e.target.value, group, index)}
                                            onBlur={onBlur}
                                            className="ac-response-ssml" />}
                                    {answer.useEnhancedResponse &&
                                        <>
                                            <TempLabel>
                                                <TempDescription>Temperature</TempDescription>
                                                <IconTooltip text='Higher temperatures result in more creative responses<br />at the risk of less consistent and controlled output.' />
                                            </TempLabel>
                                            <HorizontalOptionsSelector
                                                name={`responses.${index}.temperature`}
                                                options={temperatureOptions}
                                                value={answer.temperature ?? .1}
                                                isCustom={false}
                                                onChange={(value) => {
                                                    this.handleTemperature(value, group, index);
                                                }}
                                            />

                                        </>}
                                    <SsmlField
                                        id={`g${groupIndex}response${index}`}
                                        applicationId={applicationId}
                                        disabled={isDisabled}
                                        name={`responses.${index}.content`}
                                        value={answer.content}
                                        placeholder="A Phrase"
                                        label={answer.useEnhancedResponse ? `Backup Response` : `Device Response`}
                                        onChange={(value) => this.handleSsmlUpdate(value, group, index)}
                                        onBlur={onBlur}
                                        className="ac-response-ssml" />
                                </div>
                            </Fragment>
                        ))}
                        <HorizontalSeparator />
                        <Button type="button" disabled={isDisabled} themes={["secondary-medium"]} text="Add a Variation" onClick={() => this.handleAddSsmlVariation(group)} />
                    </FormFieldsContainer>
                </ContentCard>
                <MediaSelectionCard onClick={() => this.handleUploadMedia(group)}
                    hasBackground={group.firstResponse.backgroundImage != null}
                    hasAudio={group.firstResponse.audio != null}
                    hasImage={group.firstResponse.largeImage != null}
                    hasVideo={group.firstResponse.video != null}
                    disabled={isDisabled}
                    anchor={`g${groupIndex}media`} />

                {!hideFollowUp && <ManageFollowUpForm
                    followUp={group.firstResponse.followUp}
                    stateContainer={followUpContainer}
                    featureContainer={featureContainer}
                    applicationId={this.props.applicationId}
                    emptyOptionText={this.props.emptyFollowUpOptionText}
                    applicationFeatureId={this.props.applicationFeatureId}
                    emptyOptionIcon={this.props.emptyFollowUpOptionIcon}
                    childContentContainer={group.firstResponse?.followUp?.childContentContainer}
                    followUpContainer={this.props.followUpContainer}
                    isLoading={followUpContainer.state.isLoadingOne || featureContainer.state.isLoadingSystemFeatures}
                    onChange={(followUp, contentContainer) => this.handleFollowUpChange(followUp, contentContainer, group)}
                    onManage={() => this.handleShowFollowUpModal(group)}
                    languageIds={this.props.languages?.map(l => l.id)}
                    disabled={isDisabled}
                    groupIndex={groupIndex} />}
                {group.firstResponse.followUp &&
                    <ManageRepromptForm
                        applicationId={this.props.applicationId}
                        repromptContainer={repromptContainer}
                        onChange={(r) => this.handleRepromptChange(r, group)}
                        reprompt={group.firstResponse.reprompt}
                        onManage={() => this.handleShowRepromptModal(group)}
                        groupIndex={groupIndex} />}
            </ContentCollapsingSection>
        );
    };

    render() {
        const { responseGroups } = this.props;
        const responseGroupsOrdered = _.orderBy(responseGroups, ['priority', 'key']);
        return (
            <>
                {responseGroupsOrdered.map((group, groupIndex) => {
                    const priorGroup = responseGroupsOrdered[groupIndex - 1];
                    const contentToRender = this.renderResponseGroupContent(group, groupIndex, priorGroup, false, false);
                    return contentToRender;
                })}
                <AddResponseSection onAddNew={this.handleAddConditionResponse.bind(this)} onCloneNew={this.handleCloneConditionResponse.bind(this)} />

                {this.state.isMediaToggled ?
                    <MediaResponseManager
                        isVisible={true}
                        applicationId={this.props.applicationId}
                        audioItem={this.state.editingResponseGroup?.firstResponse?.audio}
                        videoItem={this.state.editingResponseGroup?.firstResponse?.video}
                        imageItem={this.state.editingResponseGroup?.firstResponse?.largeImage}
                        backgroundImageItem={this.state.editingResponseGroup?.firstResponse?.backgroundImage}
                        displayTextOverride={this.state.editingResponseGroup?.firstResponse?.displayTextOverride}
                        displayTitleOverride={this.state.editingResponseGroup?.firstResponse?.displayTitleOverride}
                        container={this.state.editingResponseGroup?.firstResponse?.mediaResponseContainer}
                        mediaResponseContainerId={this.state.editingResponseGroup?.firstResponse?.mediaResponseContainer?.id ?? this.state.editingResponseGroup?.firstResponse?.mediaResponseContainerId}
                        onClose={this.handleUploadMediaClose.bind(this)}
                        onFinish={this.handleMediaSelected.bind(this)} />
                    : null}
                {this.state.isRepromptToggled &&
                    <RepromptManager applicationId={this.props.applicationId}
                        onClose={this.handleRepromptClose.bind(this)}
                        onFinish={this.handleRepromptChange.bind(this)}
                        reprompt={this.state.editingResponseGroup?.firstResponse?.reprompt} />}

                {this.state.isFollowUpToggled &&
                    <FollowUpManager applicationId={this.props.applicationId}
                        onClose={this.handleFollowUpClose.bind(this)}
                        onFinish={this.handleFollowUpChange.bind(this)}
                        followUp={this.state.editingResponseGroup?.firstResponse?.followUp} />}


                {this.state.isConditionToggled &&
                    <ConditionManager onFinish={this.handleConditionChange.bind(this)}
                        onCancel={this.handleCancelCondition.bind(this)}
                        conditions={this.props.conditionsContainer.state.conditions}
                        defaultConditionInstance={this.state.editingCondition}
                        deviceTypes={this.props.mediaResponseContainer.state.deviceTargets}
                        flags={this.props.appFlagsContainer.state.applicationFlags} />}
                {this.state.isFlagsToggled &&
                    <FlagRuleCreator onFinish={this.handleAddFlagToGroup.bind(this)}
                        onCancel={this.handleCancelFlags.bind(this)}
                        flags={this.props.appFlagsContainer.state.applicationFlags?.filter(f => f.applicationId == this.props.applicationId)}
                        applicationId={this.props.applicationId} />}
                {this.state.isEffectsToggled &&
                    <EffectManager onFinish={this.handleEffectManagerFinish.bind(this)}
                        onCancel={this.handleCancelEffects.bind(this)}
                        editingEffect={this.state.editingEffect} />
                }
            </>
        )
    }
}

const RequiredContainer = styled.div`
    display: flex;
    align-items: center;
    line-height: 16px;
`
const RequiredAsterixIcon = styled.img`
    color: ${color_colors_decline};
    width: 10px;
`
const RequiredText = styled.p`
    size: 12px;
    margin-right: 9px;
    color: ${color_shades_darkest};
`
const ContentCardSubText = styled.div`
    display: flex;
    align-items: center;
    margin-top: 8px;
    size: 12px;
    line-height: 16px;  
    color: ${color_shades_darker};
`
const SubTextIcon = styled.img`
    margin-right: 8px;
`
const HighlightSection = styled.div(`
    padding: 32px 32px 0 32px;
    margin: -32px -32px 32px -32px;
    background: ${color_variants_ocean_light_opaque};
    border-bottom: ${style_border_default};
`)

const GenerativeSwitch = styled(Switch)`
    margin-left: 8px;
`;
const ConditionalGenerativeSwitch = styled(GenerativeSwitch)`
    margin-right: 8px;
`;
const menuWrapperStyle = css`
    margin-right: 8px;
    margin-left: auto;
    display: flex;
    align-items: center;
    .flags-button {
        height: 32px;
        padding: 0 24px;
        background: ${color_gradients_silver_shift};
        border: ${style_border_default};
        box-shadow: none;
        border-radius: 16px;
        box-sizing: border-box;
        color: ${color_text_default};
        margin-right: 16px;
        font-size: 14px;
        line-height: 20px;
        cursor: pointer;
        display: flex;
        align-items: center;
        font-family: 'Muli';
        
        .count-number {
            color: white;
            border-radius: 50%;
            width: 16px;
            height: 16px;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            margin-left: 8px;
            background: ${color_shades_darkest};
            font-family: 'Muli';
        }
        &.active {
            background: ${color_variants_ocean_light_opaque};
            border-color: ${color_colors_ocean};
            color: ${color_text_link};
        }
        img {
            margin-left: 8px;
        }
    }
`
const TempDescription = styled.p`
    margin-bottom: 16px;
    margin-left: 16px;
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    margin-right: 8px;
`
const TempLabel = styled.div`
    display: flex;
    flex-direction: row;
`


export default Responses;